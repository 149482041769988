import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { Checkbox, ICheckboxStyles } from '@fluentui/react'
import { IVgtFieldPropsBase } from 'lib/IVgtFieldPropsBase'

interface IVgtFieldProps extends IVgtFieldPropsBase {
  name: string
  control: any
  register?: any
  label?: string
  errors?: any
  required?: boolean
  defaultValue?: boolean
  styles?: Partial<ICheckboxStyles>
  height?: string
  readOnly?: boolean
  disabled?: boolean
  checked?: boolean
  initialChecked?: boolean
  setValue?: (name: string, value: unknown, config?: Partial<{
    shouldValidate: boolean;
    shouldDirty: boolean;
  }> | undefined) => void
  setCheckedState?: (checked: boolean | undefined) => void
  afterChange?: (value) => void
}

export function useFluentCheckbox(props: IVgtFieldProps) {
  const [checked, setChecked] = useState(props.initialChecked);
  const setCheckState = (checked: boolean | undefined) => {
    setChecked(checked);
    if(props.setValue)
      props.setValue(props.name, checked, {shouldDirty: true});
  }
  props.checked = checked;
  return [(<VgtCheckbox {...props} setCheckedState={setCheckState} />), checked, setCheckState] as const;
}

const VgtCheckbox: React.FC<IVgtFieldProps> = ({ ...props }) => {

  const checkboxStyles: Partial<ICheckboxStyles> = {
    root: {
      padding: '5px 0px 5px 12px',
      height: props.height
    },
    text: {
      width: 215,
      fontWeight: 'normal',
    },
    checkbox: {
      marginLeft: 0,
    },
  }

  const onValueChange = (onChange: (...event: any[]) => void, isChecked: boolean | undefined) => {
    if (props.readOnly) {
      return null
    }
    else {
      onChange(isChecked);
      if (props.setCheckedState)
        props.setCheckedState(isChecked);
    }
    if (props.afterChange) props.afterChange(isChecked);
  }

  return (
    <div hidden={props.showIf !== undefined && !props.showIf}>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.defaultValue ? props.defaultValue : false}
        render={({ onChange, value }) => (
          <Checkbox
            styles={props.styles ? props.styles : checkboxStyles}
            name={props.name}
            label={props.label}
            // componentRef={props.register}
            onChange={(_e, isChecked) => {
              onValueChange(onChange, isChecked)
            }}
            boxSide={'end'}
            checked={props.checked === undefined ? value : props.checked}
            disabled={props.disabled}
          />
        )}
      />
    </div>
  )
}

export default VgtCheckbox
