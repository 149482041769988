import {
  CommandBar,
  DetailsList,
  ICommandBarItemProps,
  Link,
  SelectionMode,
  Spinner,
  SpinnerSize
} from '@fluentui/react'
import commandBarStyles from '../../../styles/commandBarStyles'
import { IInstallatie } from '../installatie/installatieTypes'
import { FC } from 'react'
import { createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { transformDateString } from '../lemdo/common/support'
import { createCommandButton } from '../../../lib/commandBarHelper'
import { getInstallaties, getLoadingState } from './CartotheekStateMachine'
import { useSelector } from 'react-redux'

export interface IInstallatieGridProps {
  onSelected: (item: IInstallatie) => void
  onClose?: () => void
  onAdd?: () => void
}

export const CartotheekList: FC<IInstallatieGridProps> = (props: IInstallatieGridProps) => {
  const installaties = useSelector(getInstallaties)
  const loadingState = useSelector((state: any) =>  getLoadingState(state))

  const columns = [
    createColumn('Cartotheek', getPropertyName<IInstallatie>('elementdetailDisplay'), 'XXL', true,
      (item: IInstallatie) => <Link key={item.id} onClick={() => props.onSelected(item)}>{item.categorieDisplay} {item.elementDisplay ? ' - ' : ''}{item.elementDisplay} {item.elementdetailDisplay ? ' - ' : ''}{item.elementdetailDisplay}</Link>),
    createColumn('Aantal', getPropertyName<IInstallatie>('productAantal'),'L'),
    createColumn('Ruimte', getPropertyName<IInstallatie>('ruimte'),'L'),
    createColumn('Beheerder', getPropertyName<IInstallatie>('beheerderDisplay'), 'XXL'),
    createColumn('Plaatsingsdatum', getPropertyName<IInstallatie>('plaatsingDatum'), 'L', false, (item: IInstallatie) => transformDateString(item.plaatsingDatum)),
    createColumn('Garantiedatum t/m', getPropertyName<IInstallatie>('garantieEindDatum'), 'L', false, (item: IInstallatie) => transformDateString(item.werkzaamhedenEindDatum))
  ]
  const createCommandBarItems = () => {
    let result: ICommandBarItemProps[]  = []

    if (props.onAdd !== undefined) {
      result.push(createCommandButton('Nieuw', 'Add', () => props.onAdd!()))
    }

    return result
  }

  return (
    <>
      <CommandBar items={createCommandBarItems()} styles={commandBarStyles} />
      <DetailsList items={installaties} columns={columns} onItemInvoked={props.onSelected} selectionMode={SelectionMode.none} />
      {loadingState === "pending" && <Spinner size={SpinnerSize.large} />}
    </>
  )
}