import React, { useEffect, useMemo, useState } from 'react'
import {
  CommandBar,
  IColumn,
  ICommandBarItemProps,
  Link,
} from '@fluentui/react'
import commandBarStyles from 'styles/commandBarStyles'
import {IVge} from 'features/vge/vgeSlice'
import {getPropertyName} from 'lib/interfaceUtils'
import {useBoolean} from '@fluentui/react-hooks'
import SearchVgeForLinkageToGebouw from './searchVgeForLinkageToGebouw'
import {createBooleanColumn, createColumn, createCustomRenderedColumn} from 'lib/gridHelper'
import {createCommandButton} from 'lib/commandBarHelper'
import {PageIdDefinition, PAGES} from 'pages'
import {useNavigate} from 'react-router-dom'
import {useWarningDialog} from 'components/WarningDialog'
import { GebouwVgeListDto, zodiosHooks } from '../../../../api/ApiClient'
import {getTitleAndMessage} from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import {VgeHistorischPanel} from "../../../vge/VgeHistorischPanel";
import { usePagedParams } from '../../../../services/usePagedParams'
import { z } from 'zod'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { CreateUpdateType } from './gebouwEdit'


type GebouwVgeListDtoType = z.infer<typeof GebouwVgeListDto>;

interface IGebouwAdressenProps {
  gebouw: CreateUpdateType
  updateSelectedGebouw: any
  adresStatus: number
  setItemCount: (count: number) => void
}

export enum AdresStatus {
  MetAdres = 1,
  ZonderAdres = 2,
  MetTerrein = 3
}

const GebouwVgeList: React.FC<IGebouwAdressenProps> = props => {
  const [isPanelOpen, {setTrue: openPanel, setFalse: dismissPanel}] = useBoolean(false)
  const navigate = useNavigate()
  const { validatedSearchParams, setOrder, setPage } = usePagedParams()
  const [error, setError] = useState<string>()
  const [WarningDialog, setDialogSubtext, showDialog] = useWarningDialog()
  const [inHistorischMode, { setTrue: openHistorischPanel, setFalse: dismissHistorischPanel }] = useBoolean(false)
  const [historischButton,{setTrue: showHistorischButton, setFalse: hideHistorischButton }] = useBoolean(false)
  const [selectedIds, setSelectedIds] = useState([] as number[])

  const onModified = () => {
    dismissHistorischPanel()
  }  

  const {data: vges, invalidate: fetchVges, isLoading} = zodiosHooks.useGetGebouwenGebouwIdvges({
    params: { gebouwId: props.gebouw.gebouwId as number },
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter,
      adresStatus: props.adresStatus
    }
  }, {enabled: props.gebouw.gebouwId !== 0})

  const {mutate: deleteVgesFromGebouw} = zodiosHooks.useDeleteGebouwenVge({}, {
    onSuccess: () => fetchVges(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  useEffect(() => {
    if (vges && vges.items) {
      props.setItemCount(vges.items.length);
    }
  }, [vges, props.setItemCount]);

  const onSelectionChanged = (ids: number[]) => {
    ids.length > 0 ? showHistorischButton() : hideHistorischButton()
    setSelectedIds(ids)
  }

  const CheckForServiceadres = (vgeId: number, vgesSelected: number[]) => {
    if (props.gebouw?.serviceadresVge && vgesSelected.find((v: number) => v === props.gebouw?.serviceadresVge)) {
      setDialogSubtext('Vastgoedeenheid kan niet worden ontkoppeld aangezien deze wordt gebruikt als serviceadres')
      showDialog(true)
    } else {
      deleteVgesFromGebouw({gebouwId: props.gebouw.gebouwId as number, vgeIds: [vgeId]})
    }
  }

  const AddToSelection = () => {
    props.updateSelectedGebouw()
    openPanel()
  }

  const RemoveFromSelection = () => {
    if (selectedIds.length > 0) {
      props.updateSelectedGebouw()

      selectedIds.forEach(vgeId => {
        CheckForServiceadres(vgeId, selectedIds)
      })
    }
  }

  const selectCommandBarItems: ICommandBarItemProps[] = useMemo(
    () => [
      createCommandButton('Koppelen', 'PlugConnected', AddToSelection),
      createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveFromSelection),
      createCommandButton('Historisch maken', 'Edit', openHistorischPanel, historischButton)],
    [AddToSelection, RemoveFromSelection, historischButton, selectedIds]
  )

  const handleItemInvoked = ({vgeId}: GebouwVgeListDtoType) => {
    navigate(PAGES[PageIdDefinition.vge.edit].route.replace(':id', vgeId.toString()))
  }

  const columns: IColumn[] = [
    createColumn('Eenheiddetailsoort', getPropertyName<GebouwVgeListDtoType>('srtNm'), 'XL'),
    createColumn('Woonplaats', getPropertyName<GebouwVgeListDtoType>('wpl')),
    createCustomRenderedColumn(
      'Straat',
      getPropertyName<GebouwVgeListDtoType>('str'),
      (item: GebouwVgeListDtoType) => (
        <Link key={item.str} onClick={() => handleItemInvoked(item)}>
          {item.str}
        </Link>
      ),
      'XL',
      false
    ),
    createColumn('Huisnr.', getPropertyName<GebouwVgeListDtoType>('nr')),
    createColumn('Huislet.', getPropertyName<GebouwVgeListDtoType>('ltr')),
    createColumn('Toev.', getPropertyName<GebouwVgeListDtoType>('nrT')),
    createColumn('Postcode', getPropertyName<GebouwVgeListDtoType>('pc')),
    createColumn('Gebouwdeel', getPropertyName<GebouwVgeListDtoType>('gdlCd')),
    createCustomRenderedColumn('Verhuureenheid', getPropertyName<GebouwVgeListDtoType>('vhes'), item => item[getPropertyName<IVge>('vhes')].join(', '), 125, false),
    createColumn('Beheerder', getPropertyName<GebouwVgeListDtoType>('bn'), 'XL'),
    createBooleanColumn('Historisch', getPropertyName<GebouwVgeListDtoType>('hist'), 'S')
  ]

  return (
    <>
      <VgeHistorischPanel onModified={onModified} dismissPanel={dismissHistorischPanel} isOpen={inHistorischMode}
                          vgeIds={selectedIds}/>
      <CommandBar items={selectCommandBarItems} styles={commandBarStyles}/>
      {WarningDialog}
      <ErrorMessageBar error={error}/>
      <SearchVgeForLinkageToGebouw
        dismissPanel={dismissPanel}
        isOpen={isPanelOpen}
        gebouw={props.gebouw}
        adresStatus={props.adresStatus}
        invalidate={fetchVges}
      />
      <div style={{height: '70vh', position: 'relative'}}>
        <VgtPagedTable
          isLoading={isLoading}
          items={vges?.items ?? []}
          columns={columns}
          height={'203'}
          pagingInfo={{
            pageSize: validatedSearchParams.pageSize,
            currentPage: validatedSearchParams.page,
            totalCount: vges?.totalCount ?? 0,
            totalPages: vges?.totalPages ?? 0,
            hasNextPage: vges?.hasNextPage ?? false,
            hasPreviousPage: vges?.hasPreviousPage ?? false,
            sortKey: validatedSearchParams.sortKey || 'vgeId',
            sortDirection: validatedSearchParams.sortDirection || 'desc'
          }}
          onSelectionChanged={onSelectionChanged}
          getKey={(item) => item?.vgeId}
          onItemInvoked={(item) => handleItemInvoked(item)}
          onPageChanged={setPage}
          onSortChanged={setOrder}
        />
      </div>
    </>
  )
}

export default GebouwVgeList
