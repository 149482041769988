import React, { useState } from 'react'
import {
  DetailsList,
  IconButton,
  IDetailsHeaderStyles,
  IDetailsListProps,
  IStackStyles,
  Link,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Text
} from '@fluentui/react'
import { z } from 'zod'
import { GerelateerdGebouwDeelListDto, GerelateerdGebouwListDto } from '../../../../api/ApiClient'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { useNavigate } from 'react-router-dom'
import { createCustomRenderedColumn } from '../../../../lib/gridHelper'
import { DetailsHeader } from '@fluentui/react/lib/DetailsList'
import { availableUrls } from '../../../../routing/AvailableUrls'

type GebouwDtoType = z.infer<typeof GerelateerdGebouwListDto>;
type GebouwDelenDtoType = z.infer<typeof GerelateerdGebouwDeelListDto>;

interface IClusterGebouwenRowProps {
  item: GebouwDtoType;
}

const ClusterGebouwenRow: React.FC<IClusterGebouwenRowProps> = ({
                                                                  item
                                                                }) => {
  const navigate = useNavigate()
  const [showGebouwDelenTable, setShowGebouwDelenTable] = useState<boolean>(false)

  const stackStyles: IStackStyles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '20px',
      '&:hover': {
        backgroundColor: '#f3f3f3'
      },
      marginLeft: 30
    }
  }

  const onGebouwDelenClick = (id: string) => {
    navigate(availableUrls.Gebouwdeel.replace(':id', id))
  }

  const handleItemInvoked = (id: string) => {
    navigate(availableUrls.GebouwEdit.replace(':id', id))
  }

  const columns = [
    createCustomRenderedColumn(
      'Gebouwdeelcode',
      getPropertyName<GebouwDelenDtoType>('code'),
      (item: GebouwDelenDtoType) => (
        <Link key={item.gebouwDeelId} onClick={() => onGebouwDelenClick(item.gebouwDeelId.toString())}>
          {item.code}
        </Link>
      ),
      'L',
      false
    ),
    createCustomRenderedColumn(
      'Gebouwdeelnaam',
      getPropertyName<GebouwDelenDtoType>('naam'),
      (item: GebouwDelenDtoType) => <span style={{ fontSize: 14, fontWeight: 600 }}>{item.naam}</span>
    )
  ]

  const _onRenderDetailsHeader: IDetailsListProps['onRenderDetailsHeader'] = props => {
    const headerStyles: Partial<IDetailsHeaderStyles> = {
      root: {
        margin: 0,
        padding: 0
      }
    }
    if (props) {
      return <DetailsHeader {...props} ariaLabelForToggleAllGroupsButton={'Toggle selection'} styles={headerStyles} />
    }
    return null
  }

  return (
    <>
      <Stack styles={stackStyles}>
        {item?.gebouwDelen?.length! > 0 ?
          <IconButton iconProps={{ iconName: showGebouwDelenTable ? 'Remove' : 'Add' }}
                      onClick={() => setShowGebouwDelenTable(!showGebouwDelenTable)} style={{ width: 60 }} /> :
          <span style={{ width: 60 }}></span>}
        <Link key={item.gebouwId} onClick={() => handleItemInvoked(item.gebouwId.toString())}
              style={{ width: 150 }}>{item.code}</Link>
        <Text style={{ width: 275 }}>{item.naam}</Text>
      </Stack>
      {showGebouwDelenTable && (
        item?.gebouwDelen ?
          (item?.gebouwDelen?.length > 0 &&
            <div style={{ marginLeft: 100 }}>
              <DetailsList
                compact={true}
                columns={columns}
                styles={{ root: { fontSize: '14px' } }}
                items={item?.gebouwDelen}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={_onRenderDetailsHeader}
              />
            </div>
          )
          : <Spinner size={SpinnerSize.large} />)}
    </>
  )
}

export default ClusterGebouwenRow
