import React, { useEffect, useMemo, useState } from 'react'
import { IDropdownOption, Panel, PanelType } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { getPropertyName } from 'lib/interfaceUtils'
import VgtTextField from 'components/VgtTextField'
import VgtDropdown from 'components/FluentDropdown'
import useRoles from 'services/UseRoles'
import { addZeroDropdownOption } from 'lib/controlSupport'
import { CreateElementCommand, ElementDto, zodiosHooks } from '../../../../api/ApiClient'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

type ElementDtoType = z.infer<typeof ElementDto>;
type CreateElementCommandType = z.infer<typeof CreateElementCommand>;

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  fetchElementItems: () => void
  selectedElementItem: ElementDtoType | undefined
}

const EditPanelElement: React.FC<IEditPanelProps> = ({
                                                       isOpen,
                                                       dismissPanel,
                                                       selectedElementItem,
                                                       fetchElementItems
                                                     }) => {
  const [title, setTitle] = useState('Element')
  const { isAdmin } = useRoles()
  const [error, setError] = useState<string>()

  const onDataSubmitttedSuccesfully = () => {
    fetchElementItems()
    dismissPanel()
  }

  const { data: FontIcoonOptions } = zodiosHooks.useGetApiFonticoon()

  const { mutate: postApiElement, isLoading: isPostingElement } = zodiosHooks.usePostApiElement({}, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: putApiElement, isLoading: isUpdatingElement } = zodiosHooks.usePutApiElementId(
    { params: { id: selectedElementItem?.id! } }, {
      onSuccess: () => onDataSubmitttedSuccesfully(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const {
    data: elementCategories
  } = zodiosHooks.useGetApiElementCategoriepage({})

  const fontIcons = FontIcoonOptions?.items?.filter(item => item.naam !== null)
    .map(item => ({ key: item.fonticoonId, text: item.omschrijving! }))

  const categorieenRaw = elementCategories?.items?.map((item) => {
    return {
      key: item.id,
      text: item.naam
    } as IDropdownOption
  }) || []

  const categorieen = useMemo(() => addZeroDropdownOption(categorieenRaw), [elementCategories?.items])

  const defaultElementen = {
    id: selectedElementItem?.id ?? 0,
    naam: selectedElementItem?.naam ?? '',
    categorieId: selectedElementItem?.categorieId ?? null,
    categorieNaam: selectedElementItem?.categorieNaam ?? null,
    ingangsDatum: new Date().toJSON(),
    eindDatum: selectedElementItem?.eindDatum ?? null,
    fonticoonId: selectedElementItem?.fonticoonId ?? null,
    fonticoonNaam: selectedElementItem?.fonticoonNaam ?? null
  }

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateElementCommandType>({ mode: 'all' })

  useEffect(() => {
    setTitle(`Element ${selectedElementItem ? (isAdmin ? 'wijzigen' : '') : 'toevoegen'}`)
    if (selectedElementItem) {
      reset(selectedElementItem)
    } else {
      reset({})
    }
  }, [selectedElementItem])

  const onCancel = () => {
    dismissPanel()
  }

  const onSubmit = (data: CreateElementCommandType) => {
    const {
      categorieId = null,
      fonticoonId = null,
      ...restData
    } = data

    const submittedData = {
      ...defaultElementen,
      categorieId: categorieId ?? null,
      fonticoonId: fonticoonId ?? null,
      ...restData
    }

    if (selectedElementItem) {
      submittedData.id = selectedElementItem.id
      putApiElement(submittedData)
    } else {
      postApiElement(submittedData)
    }
  }

  return (
    <div>
      <Panel type={PanelType.medium} headerText={title} isOpen={isOpen} onDismiss={onCancel}
             closeButtonAriaLabel='Sluiten'>
        <ErrorMessageBar error={error} />
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtTextField
            label='Code'
            name={getPropertyName<CreateElementCommandType>('naam')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
          />
          <VgtDropdown
            options={categorieen}
            label='Elementcategorie'
            name={getPropertyName<CreateElementCommandType>('categorieId')}
            register={register}
            control={control}
            errors={errors}
            readOnly={!isAdmin}
          />
          <VgtDropdown
            options={fontIcons}
            label='Fonticoon'
            name={getPropertyName<CreateElementCommandType>('fonticoonId')}
            register={register}
            control={control}
            errors={errors}
            readOnly={!isAdmin}
          />
          <OkCancelButtonStack
            isSubmitting={isSubmitting}
            isLoading={isPostingElement || isUpdatingElement}
            onOkClick={handleSubmit(onSubmit)}
            onCancelClick={onCancel}
            isWriter={isAdmin}
          />
        </form>
      </Panel>
    </div>
  )
}

export default EditPanelElement
