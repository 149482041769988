import React, { useCallback, useState } from 'react'
import {
  Checkbox,
  IButtonStyles,
  ICheckboxStyles,
  IconButton,
  IStackStyles,
  Link,
  Spinner,
  SpinnerSize,
  Stack,
  Text
} from '@fluentui/react'
import { z } from 'zod'
import { GebouwenClusterListDto } from '../../../../api/ApiClient'
import { useNavigate } from 'react-router-dom'
import { availableUrls } from '../../../../routing/AvailableUrls'
import ClusterGebouwenRow from './clusterGebouwenRow'

type GebouwenClusterDtoType = z.infer<typeof GebouwenClusterListDto>;

interface IGebouwenRowProps {
  item: GebouwenClusterDtoType;
  index: number;
  handleItemSelectionChanged: (id: number) => void;
}

const checkBoxStyles: Partial<ICheckboxStyles> = {
  checkbox: {
    borderRadius: '50%'
  }
}

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    width: 60,
    height: 'auto'
  }
}

const gebouwenTableStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '5px',
    overflow: 'auto',
    margin: 10
  }
}

const GebouwenClusterRow: React.FC<IGebouwenRowProps> = ({
                                                           item,
                                                           handleItemSelectionChanged,
                                                           index
                                                         }) => {
  const navigate = useNavigate()
  const [showGebouwenTable, setShowGebouwenTable] = useState<boolean>(false)
  const [itemChecked, setItemChecked] = useState<boolean>(false)

  const stackStyles: IStackStyles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '20px',
      backgroundColor: index % 2 === 0 ? '#f1f1f1' : '#ffffff',
      '&:hover': {
        backgroundColor: '#f3f3f3'
      }
    }
  }

  const itemStackStyles: IStackStyles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '20px'
    }
  }

  const onGebouwenClusterClick = (id: string) => {
    navigate(availableUrls.GebouwenClusterEdit.replace(':id', id))
  }

  const onItemSelection = (id: number) => {
    setItemChecked(!itemChecked)
    handleItemSelectionChanged(id)
  }

  const onCheckboxChange = useCallback(
    (): void => {
      setItemChecked(!itemChecked)
      handleItemSelectionChanged(item?.gebouwClusterId)
    },
    [itemChecked]
  )

  return (
    <>
      <Stack styles={stackStyles}>
        {item?.gerelateerdGebouwListDto?.length! > 0 ?
          <IconButton iconProps={{ iconName: showGebouwenTable ? 'Remove' : 'Add' }}
                      onClick={() => setShowGebouwenTable(!showGebouwenTable)}
                      styles={iconButtonStyles}
          /> :
          <span style={{ width: 60 }}></span>}
        <Checkbox checked={itemChecked} onChange={onCheckboxChange} styles={checkBoxStyles} />
        <Stack onClick={() => onItemSelection(item.gebouwClusterId)} styles={itemStackStyles}>
          <Link key={item.gebouwClusterId} onClick={() => onGebouwenClusterClick(item.gebouwClusterId.toString())}
                style={{ width: 150 }}>{item.code}</Link>
          <Text style={{ width: 'auto' }}>{item.naam}</Text>
        </Stack>
      </Stack>
      {showGebouwenTable && (
        item?.gerelateerdGebouwListDto ?
          (item?.gerelateerdGebouwListDto?.length > 0 &&
            <Stack styles={gebouwenTableStyles}>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
                <span style={{ width: 90 }}></span>
                <Text style={{ width: 150, fontWeight: '600' }}>Gebouwcode</Text>
                <Text style={{ width: 275, fontWeight: '600' }}>Gebouwnaam</Text>
              </div>
              {item?.gerelateerdGebouwListDto?.map((item) =>
                <ClusterGebouwenRow key={item.gebouwId} item={item} />
              )}
            </Stack>
          )
          : <Spinner size={SpinnerSize.large} />)}
    </>
  )
}

export default GebouwenClusterRow
