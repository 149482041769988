import { ITextFieldStyles, TextField } from '@fluentui/react'
import React, { useState } from 'react'
import { Controller, Control } from 'react-hook-form'
import { IVgtFieldPropsBase } from '../../lib/IVgtFieldPropsBase'

interface IDecimalInputProps extends IVgtFieldPropsBase {
  name: string
  label: string
  control: Control;
  errors: any
  register?: any
  required?: boolean
  readOnly?: boolean
  iconProps?: any
  defaultValue?: number;
  height?: string | number
  labelWidth?: number
  disabled?: boolean
}

const DecimalInput: React.FC<IDecimalInputProps> = props => {
  const labelWidth = props.labelWidth ? props.labelWidth : 215
  const regExNumber = /^(\d+(\.\d+)?).*/
  const [text, setText] = useState<string>()
  const parseLocaleNumber = (stringValue: string) => {
    if (!regExNumber.test(stringValue)) {
      setText('')
      return
    }

    const numberText = stringValue.replace(',', '.')
    const number = Number(numberText)
    setText(numberText)
    return isNaN(number) ? undefined : number

  }
  const textFieldStyles: Partial<ITextFieldStyles> = {
    wrapper: {
      height: props.height,
      borderRadius: '5px',
      border: '1px solid rgb(255 0 0 / 6%)'
    },
    subComponentStyles: {
      label: {
        root: { width: labelWidth, fontWeight: 'normal' }
      }
    },
    field: {
      fontWeight: 600
    },
    suffix: {
      background: ''
    }
  }

  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={props.defaultValue}
      render={({ onChange, value }) => {
        return (
          <div hidden={props.showIf !== undefined && !props.showIf}>
            <TextField
              disabled={props.disabled}
              value={text ?? value}
              autoComplete="off"
              onChange={(e, val) => onChange(parseLocaleNumber(val ?? ''))}
              type="text"
              styles={textFieldStyles}
              iconProps={props.iconProps}
              required={props.required}
              readOnly={props.readOnly}
              placeholder=" --- "
              label={props.label}
              borderless
              underlined
              errorMessage={props.errors[props.name]?.message}
            />
          </div>
        )
      }}
    />
  )
}

export default DecimalInput