import { Panel, PanelType } from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { VgeRuimteAddOrUpdateDto, zodiosHooks } from '../../../api/ApiClient'
import { z } from 'zod'
import VgtDropdown from '../../../components/FluentDropdown'
import { getPropertyName } from '../../../lib/interfaceUtils'
import React, { useEffect, useState } from 'react'
import VgtTextField from '../../../components/VgtTextField'
import { OkCancelButtonStack } from '../../../components/OkCancelButtonStack/OkCancelButtonStack'
import { getTitleAndMessage } from '../../../services/HandleError'
import VgtNumberField from '../../../components/FluentNumberField'
import VgtMultiselectDropdown from '../../../components/FluentMultiselectDropdown'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'
import useRoles from '../../../services/UseRoles'

export interface VgeRuimtePanelProps {
  isOpen: boolean;
  dismissPanel: () => void;
  vgeRuimteId: number;
  vgeId: number;
  invalidateParent?: () => void;
}

type CreateOrUpdateType = z.infer<typeof VgeRuimteAddOrUpdateDto>;

const defaultVgeRuimte = {
  nummer: '',
  oppervlakte: 0,
  verwarmingOptieId: 0,
  koelingOptieId: 0

} as CreateOrUpdateType


export const VgeRuimtePanel: React.FC<VgeRuimtePanelProps> = ({
                                                                vgeId,
                                                                vgeRuimteId,
                                                                isOpen,
                                                                dismissPanel,
                                                                invalidateParent
                                                              }) => {
  const [error, setError] = useState<string>()
  const { data: ruimtesoortOpties } = zodiosHooks.useGetRuimteSoortopties({}, { enabled: true })
  const { data: verwarmingOpties } = zodiosHooks.useGetVerwarmingenopties({}, { enabled: true })
  const { data: koelingOpties } = zodiosHooks.useGetVgeRuimtekoelingOpties({}, { enabled: true })
  const { data: bouwlaagOpties } = zodiosHooks.useGetApiBouwlagenopties({}, { enabled: true })
  const { data: vheOpties } = zodiosHooks.useGetVgeIdvheOpties({ params: { id: vgeId } }, { enabled: true })
  const { isVastgoedbeheer } = useRoles()

  const { data, isLoading, invalidate } = zodiosHooks.useGetVgeRuimteId({ params: { id: vgeRuimteId } })
  const onMutateSuccess = () => {
    if (invalidateParent) invalidateParent()
    invalidate()
    setError(undefined)
    dismissPanel()
  }

  const { mutate: update } = zodiosHooks.usePutVgeRuimteId(
    { params: { id: vgeRuimteId ?? -1 } }, {
      onSuccess: onMutateSuccess,
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )
  const { mutate: add } = zodiosHooks.usePostVgeVgeIdruimtes(
    { params: { vgeId: vgeId ?? -1 } }, {
      onSuccess: onMutateSuccess,
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateOrUpdateType>({
    resolver: zodResolver(VgeRuimteAddOrUpdateDto), mode: 'all', defaultValues: defaultVgeRuimte
  })

  useEffect(() => {
    if (data) {
      reset(data)
      setError(undefined)
    } else {
      reset(defaultVgeRuimte)
      setError(undefined)
    }
  }, [data, reset])

  const onSubmit = (values: CreateOrUpdateType) => {
    if (vgeRuimteId === -1) {
      add(values)
    } else {
      update(values)
    }
  }

  return (
    <Panel type={PanelType.custom} customWidth='61%'
           headerText={'Ruimte ' + (vgeRuimteId === -1 ? 'toevoegen' : 'wijzigen')} isOpen={isOpen}
           onDismiss={dismissPanel} closeButtonAriaLabel='Close'>
      <div style={{ marginBottom: 16, borderBottom: '1px solid rgb(207, 207, 207)' }} />
      <form>
        <ErrorMessageBar error={error} />

        <VgtDropdown label={'Ruimtesoort'} name={getPropertyName<CreateOrUpdateType>('soortId')}
                     control={control} options={ruimtesoortOpties} required={true} errors={errors}
                     />
        <VgtTextField label='Nummer' name={getPropertyName<CreateOrUpdateType>('nummer')}
                      control={control} errors={errors} required={true} />
        <VgtDropdown label={'Bouwlaag'} name={getPropertyName<CreateOrUpdateType>('bouwlaagId')}
                     control={control} options={bouwlaagOpties} errors={errors}
                     />
        <VgtNumberField label='Oppervlakte' name={getPropertyName<CreateOrUpdateType>('oppervlakte')}
                        control={control} errors={errors} />
        <VgtDropdown label={'Verwarmd'} name={getPropertyName<CreateOrUpdateType>('verwarmingOptieId')}
                     control={control} options={verwarmingOpties} errors={errors}
                     />
        <VgtDropdown label={'Gekoeld'} name={getPropertyName<CreateOrUpdateType>('koelingOptieId')}
                     control={control} options={koelingOpties} errors={errors}
        />
        <VgtMultiselectDropdown options={vheOpties} label='Verhuureenheden'
                                name={getPropertyName<CreateOrUpdateType>('vheIds')} control={control} errors={errors}
                                />
        <OkCancelButtonStack isSubmitting={isSubmitting}
                             isLoading={isLoading ?? false}
                             isWriter={isVastgoedbeheer}
                             onOkClick={handleSubmit(onSubmit, (errors) => {
                               console.log(errors)
                               setError('Kan niet opslaan vanwege validatiefouten op de invoer.')
                             })}
                             onCancelClick={() => dismissPanel()} />
      </form>
    </Panel>
  )
}