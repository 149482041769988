import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DocumentListDto, zodiosHooks } from '../../../api/ApiClient'
import { usePagedParams } from '../../../services/usePagedParams'
import {
  ComboBox,
  CommandBar,
  DefaultButton,
  DialogContent,
  DialogFooter,
  IColumn,
  IComboBoxOption,
  IComboBoxStyles,
  ICommandBarItemProps,
  IconButton,
  ISpinnerStyles,
  Link,
  MessageBar,
  MessageBarType,
  ProgressIndicator,
  Spinner,
  SpinnerSize,
  Stack,
  Text
} from '@fluentui/react'
import commandBarStyles from '../../../styles/commandBarStyles'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { createBooleanColumn, createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import downloadFile from '../../../services/downloadFile'
import { getTitleAndMessage } from '../../../services/HandleError'
import { useBoolean } from '@fluentui/react-hooks'
import FileUploadDialog from '../../../components/FileUploadDialog'
import { v4 as uuidv4 } from 'uuid'
import { Controller, useForm } from 'react-hook-form'
import { makeRequest } from '../../../services/apiClient'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'
import FileViewer from './fileViewer'

interface IFileToUpload {
  id: string
  uploadProcessing: boolean
  soort?: string
  kenmerk?: string
  file: File
}

const comboboxStyles: Partial<IComboBoxStyles> = {
  container: { flexGrow: 1 },
  optionsContainer: { maxHeight: 300 }
}

const spinnerStyles: Partial<ISpinnerStyles> = {
  root: {
    zIndex: 100,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}

export enum DocumentDoelType {
  vge = 1,
  gebouw,
  installatie,
  gebouwenCluster,
  onderhoudsContract,
  vhe,
  vgeRuimte,
  gebouwdeel
}

export interface IEditPanelProps {
  doelType: number,
  doelId: number,
  setDocumentCount: (count: number) => void
}

export type DocumentListDtoType = z.infer<typeof DocumentListDto>;

interface IFilesDetails {
  id: number,
  extensie: string,
  bestandsnaam: string
}

export const DocumentList: React.FC<IEditPanelProps> = props => {

  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [error, setError] = React.useState<string>()
  const [isFileDialog, { setTrue: showFileDialog, setFalse: closeFileDialog }] = useBoolean(false)
  const [isFileViewer, { setTrue: showFileViewer, setFalse: closeFileViewer }] = useBoolean(false)
  const [filesToUpload, setFilesToUpload] = useState<IFileToUpload[]>([])
  const [uploadProcessing, setUploadProcessing] = useState(false)
  const [rejectedFiles, setRejectedFiles] = useState<string[]>([])
  const [kenmerkOpties, setKenmerkOpties] = useState<IComboBoxOption[]>([])
  const [documentList, setDocumentList] = useState<DocumentListDtoType>()
  const [myData, setMyData] = useState<IFilesDetails[]>()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState,
    watch,
    clearErrors
  } = useForm({ mode: 'onChange' })
  const watchFields = watch()

  const { data, invalidate, isLoading } = zodiosHooks.useGetApiDocuments({
    queries: {
      DoelType: props.doelType,
      DoelId: props.doelId,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  useEffect(() => {
    if (data && data.items) {
      props.setDocumentCount(data.items.length);
    }
  }, [data, props.setDocumentCount]);

  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteApiDocuments({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { mutate: hoofdDocumentUpdate, isLoading: isUpdating } = zodiosHooks.usePostApiDocumentshoofdDocument({}, {
    onSuccess: () => invalidate(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { data: soortKenmerkPerDoel } = zodiosHooks.useGetApiDocumentssoortKenmerkenPerDoel({
    queries: {
      DoelType: props.doelType,
      Filter: validatedSearchParams.filter
    }
  })

  const soortOpties =soortKenmerkPerDoel?.items
    ? Array.from(
      soortKenmerkPerDoel.items.reduce((map, item) => map.set(item.soort, item), new Map()).values()
    ).map(item => ({ key: item.documentSoortId, text: item.soort } as IComboBoxOption))
    : [{ key: 0, text: '---' }] as IComboBoxOption[]

  const getKenmerkPerSoort = (soortId: number) => {
    return soortKenmerkPerDoel!.items?.filter(item => item.documentSoortId === soortId).map(item => {
      return { key: item.documentKenmerkId, text: item.kenmerk } as IComboBoxOption
    }) || [{ key: 0, text: '---' }] as IComboBoxOption[]
  }

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Bestandsnaam', getPropertyName<DocumentListDtoType>('bestandsnaam'), 'XXL', true,
        (item: DocumentListDtoType) => (
          <Link key={item.documentId} onClick={() => onLinkClick(item)}>
            {item.bestandsnaam}
          </Link>
        )),
      createColumn('Soort', getPropertyName<DocumentListDtoType>('soort'), 'XL', true),
      createColumn('Kenmerk', getPropertyName<DocumentListDtoType>('kenmerk'), 'XL', true),
      createColumn('Datum', getPropertyName<DocumentListDtoType>('gemaaktOp'), 'M', false),
      createColumn('Toegevoegd door', getPropertyName<DocumentListDtoType>('gemaaktDoor'), 'XL', true),
      createColumn('Downloaden', 'Downloaden', 'M', false,
        (item: DocumentListDtoType) => (
          <IconButton text="Downloaden" iconProps={{ iconName: 'Download', title: 'Bestand downloaden' }}
                      onClick={_ => downloadDocument(item.documentId, fileName(item))} />
        )),
      createBooleanColumn('Hoofdfoto', getPropertyName<DocumentListDtoType>('isHoofdDocument'), 'L'),
    ]
  }, [])

  const commandBarItems: ICommandBarItemProps[] = useMemo(() => {
    return [
      {
        key: 'create',
        text: 'Nieuw',
        iconProps: { iconName: 'NewFolder', className: 'icon' },
        split: false,
        ariaLabel: 'Nieuw',
        onClick: () => showFileDialog()
      },
      {
        key: 'delete',
        text: 'Verwijder',
        iconProps: { iconName: 'Delete', className: 'icon' },
        split: false,
        ariaLabel: 'Verwijderen',
        disabled: selectedIds.length === 0,
        onClick: () => {
          deleteRows({ itemsToDelete: selectedIds })
        }
      },
      {
        key: 'hoofdfoto',
        text: 'Instellen als hoofdfoto',
        iconProps: { iconName: 'Photo2', className: 'icon' },
        split: false,
        ariaLabel: 'Verwijderen',
        disabled: selectedIds.length !== 1,
        onClick: () => {
          const [firstSelectedId] = selectedIds;
          hoofdDocumentUpdate({ documentId: firstSelectedId, doelType: props.doelType, doelId: props.doelId })
        }
      }
    ] as ICommandBarItemProps[]
  }, [selectedIds])

  const onLinkClick = (item: DocumentListDtoType) => {
    setDocumentList(item)
    showFileViewer()
  }

  const handleItemInvoked = () => {
    setError('Niet geïmplementeerd. Kan voorlopig niet worden geopend voor een voorbeeld.')
  }

  const downloadDocument = (documentId: number, filename: string) => {
    const url: string = `/api/documents/${documentId}/download`
    downloadFile(url, filename)
  }

  const fileName = (item: DocumentListDtoType): string => (item.bestandsnaam ?? 'document') + (item.extensie ?? '.txt')

  const handleOnFilesSelected = useCallback((files: File[]) => {
    if (files.length === 0) {
      setError('No files selected. Please select a file.')
      return
    }

    const newFilesToUpload: IFileToUpload[] = []
    for (const file of files) {
      newFilesToUpload.push({
        id: uuidv4(),
        uploadProcessing: false,
        file: file
      })
    }
    setFilesToUpload(newFilesToUpload)
  }, [])

  const disabled = uploadProcessing || isDeleting || isUpdating || filesToUpload.length === 0

  const handleDelete = useCallback(
    (id: string) => {
      if ((watchFields || {}).hasOwnProperty(id)) {
        delete watchFields[id]
      }
      onDeleteFile(id)
    },
    [watchFields]
  )

  const onDeleteFile = useCallback(
    (id: string) => {
      setFilesToUpload(prev => [...prev.filter(x => x.id !== id)])
    },
    [setFilesToUpload]
  )

  const handleSave = useCallback(
    handleSubmit(async (form: any, _) => {
      if (!props.doelId || !props.doelType) {
        setError('Document cannot be uploaded without a doelId or doelType.')
        closeFileDialog()
        return
      }

      setUploadProcessing(true)

      for (const file of filesToUpload) {
        setFilesToUpload(prev => [...prev.filter(x => x.id !== file.id), { ...file, uploading: true }])
        const data = form[file.id]

        const dskId = soortKenmerkPerDoel!.items?.find(item => item.documentSoortId === data.soortId && item.documentKenmerkId === data.kenmerkId)?.documentSoortKenmerkDoelId
        if (!dskId) {
          setError('Combinatie van soort en kenmerk is niet geldig.')
          return
        }

        //TODO: Refactor to use Zodios API
        const formData = new FormData()
        formData.append('documentSoortKenmerkDoelId', dskId?.toString())
        formData.append('doelType', props.doelType.toString())
        formData.append('doelId', props.doelId.toString())
        formData.append('file', file.file)

        try {
          await makeRequest('POST', '/api/documents', undefined, formData)
        } catch (error) {
          setError(getTitleAndMessage(error).message)
        }

        setFilesToUpload(prev => [...prev.filter(x => x.id !== file.id), { ...file, uploading: false, uploaded: true }])
      }

      onCancel()
    }),
    [setUploadProcessing, filesToUpload, setFilesToUpload]
  )

  const handleErrors = useCallback((errors: any) => {
    errors.forEach((error: string) => {
      setError(error)
    })
  }, [setError])

  const kenmerkIsEnabled = (watchFields: any, file: IFileToUpload) => {
    const soortId = watchFields[file.id]?.soortId
    return soortId !== undefined && soortId !== '' && soortId !== 0 && kenmerkOpties.length !== 0
  }

  const onCancel = () => {
    reset()
    clearErrors()
    invalidate()
    setFilesToUpload([])
    setRejectedFiles([])
    closeFileDialog()
    setUploadProcessing(false)
  }

  const onRemoveFileView = () => {
    closeFileViewer()
    setDocumentList(undefined)
  }

  useEffect(() => {
    if (data?.items) {
      const filteredItems = data.items.filter(item => item.extensie !== null)
      setMyData(filteredItems.map(item => ({
        id: item.documentId,
        extensie: item.extensie!,
        bestandsnaam: item.bestandsnaam!
      })))
    }
  }, [data?.items])

  const renderFileViewer = () => {
    if (!isFileViewer) return

    if (documentList) {
      return (
        <FileViewer data={myData!} documentList={documentList} closeDialog={onRemoveFileView} />
      )
    } else {
      return <Spinner size={SpinnerSize.large} styles={spinnerStyles} />
    }
  }

  return (
    <div className="componentWrapper">
      <ErrorMessageBar error={error} />
      <CommandBar items={commandBarItems} styles={commandBarStyles} />
      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage || false,
          hasPreviousPage: data?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.documentId}
        onSelectionChanged={setSelectedIds}
        onItemInvoked={() => handleItemInvoked()}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />

      {isFileDialog && (
        <FileUploadDialog
          title="Documenten uploaden"
          onCancel={onCancel}
          onFilesSelected={handleOnFilesSelected}
          disabled={uploadProcessing}
          multiple={true}
        >
          <DialogContent styles={{ header: { height: 0, width: 0 }, inner: { padding: 0 } }}>
            <Stack styles={{ root: { marginTop: 10 } }}>
              {rejectedFiles.length !== 0 && (
                <MessageBar messageBarType={MessageBarType.warning} dismissButtonAriaLabel="Close"
                            onDismiss={() => setRejectedFiles([])}>
                  <ul>
                    {rejectedFiles.map(e => (
                      <li key={e}>{e}</li>
                    ))}
                  </ul>
                </MessageBar>
              )}
            </Stack>
            <form onSubmit={handleSave} onError={handleErrors}>
              {filesToUpload.map(file => (
                <Stack.Item key={file.id} styles={{ root: { paddingBottom: 15 } }}>
                  <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                    <Stack.Item>
                      <Text variant="mediumPlus" styles={{ root: { fontWeight: 600 } }}>
                        {file.file.name}
                      </Text>
                    </Stack.Item>
                    <Stack.Item>
                      <IconButton
                        iconProps={{
                          iconName: 'Delete',
                          onClick: () => handleDelete(file.id)
                        }}
                        disabled={disabled}
                      />
                    </Stack.Item>
                  </Stack>
                  <ProgressIndicator
                    percentComplete={file.uploadProcessing ? 100 : file.uploadProcessing ? undefined : 0} />

                  <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }}>
                    <Controller
                      name={`[${file.id}].soortId`}
                      control={control}
                      defaultValue={''}
                      render={({ onBlur, value }) => (
                        <ComboBox
                          styles={comboboxStyles}
                          options={soortOpties}
                          allowFreeform={false}
                          autoComplete="off"
                          label="Soort"
                          required
                          selectedKey={value}
                          onBlur={onBlur}
                          onChange={(_, option, value) => {
                            const newValue = option ? (option.key as string) : value ?? ''
                            setValue(`[${file.id}].soortId`, newValue)
                            if (option) {
                              setValue(`[${file.id}].kenmerkId`, '')
                              const options = getKenmerkPerSoort(option.key as number)
                              setKenmerkOpties(options)
                            } else {
                              setKenmerkOpties([])
                            }
                            setValue(`[${file.id}].kenmerkId`, '')
                            onBlur()
                          }}
                        />
                      )}
                    />
                    {/* Field has to be on the form in order to use 'setValue' on it. */}
                    <Controller
                      name={`[${file.id}].kenmerkId`}
                      control={control}
                      defaultValue={''}
                      render={({ onBlur, value }) => (
                        <ComboBox
                          styles={comboboxStyles}
                          options={kenmerkOpties}
                          allowFreeform={false}
                          autoComplete="off"
                          label="Kenmerk"
                          selectedKey={value}
                          disabled={!kenmerkIsEnabled(watchFields, file)}
                          required={kenmerkIsEnabled(watchFields, file)}
                          onBlur={onBlur}
                          onChange={(_, option, value) => {
                            const newValue = option ? (option.key as string) : value ?? ''
                            setValue(`[${file.id}].kenmerkId`, newValue)
                            onBlur()
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Stack.Item>
              ))}
              <input type="submit" style={{ visibility: 'hidden' }} />
            </form>
          </DialogContent>
          {filesToUpload.length !== 0 && (
            <DialogFooter>
              <DefaultButton primary allowDisabledFocus disabled={disabled || !formState.isValid} onClick={handleSave}>
                Uploaden
              </DefaultButton>
            </DialogFooter>
          )}
        </FileUploadDialog>
      )}
      {renderFileViewer()}
    </div>
  )
}

export default DocumentList