import React, { useMemo } from 'react'

import { Dialog, DialogFooter, IDialogContentProps, IModalProps, DialogType, DefaultButton } from '@fluentui/react'
import { useId } from '@fluentui/react-hooks'

interface IDeleteConfirmationDialogProps {
  onConfirm: () => void
  onCancel: () => void
  entityName: string
  entityDescription?: string
  saving?: boolean
  hideDialog: boolean
}

const DeleteConfirmationDialog: React.FC<IDeleteConfirmationDialogProps> = ({
  onConfirm,
  onCancel,
  entityName,
  entityDescription,
  hideDialog
}) => {
  const labelId: string = useId('dialogLabel')
  const subTextId: string = useId('subTextLabel')

  const modalProps = useMemo(
    (): IModalProps => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: true,
    }),
    [labelId, subTextId]
  )

  const dialogContentProps = useMemo(
    (): IDialogContentProps => ({
      type: DialogType.normal,
      closeButtonAriaLabel: 'Sluiten',
      title: 'Verwijdering bevestigen',
      subText: `Weet u zeker dat u de geselecteerde ${entityName} 
       wilt verwijderen? U kunt deze actie niet ongedaan maken.`,
    }),
    [entityName, entityDescription]
  )

  return (
    <div className="deleteConfirmationDialog">
      <Dialog hidden={hideDialog} onDismiss={onCancel} dialogContentProps={dialogContentProps} modalProps={modalProps}>
        <DialogFooter>
          <DefaultButton primary onClick={onConfirm} text="Verwijderen" />
          <DefaultButton onClick={onCancel} text="Annuleren" />
        </DialogFooter>
      </Dialog>
    </div>
  )
}

export default DeleteConfirmationDialog
