const headers = {
  'X-Api-Key': 'l71f494708c42e4b78933ba43ed47bfce9',
  accept: 'application/hal+json',
  'Accept-Crs': 'epsg:28992',
}

interface IExpandedAdresseerbaarobject {
  heeftAlsHoofdAdres: { nummeraanduiding: INummeraanduiding }
  heeftAlsNevenAdres: Array<{ nummeraanduiding: INummeraanduiding }>
  maaktDeelUitVan: Array<{ pand: IPand }>
}

export interface IAddresseerbaarObjectResponse {
  verblijfsobject: {
    verblijfsobject: IAdresseerbaarobject
    _embedded: IExpandedAdresseerbaarobject
  }
  ligplaats: {
    ligplaats: IAdresseerbaarobject
    _embedded: IExpandedAdresseerbaarobject
  }
  standplaats: {
    standplaats: IAdresseerbaarobject
    _embedded: IExpandedAdresseerbaarobject
  }
}

export interface INummeraanduiding {
  identificatie: string
  huisnummer: number
  huisletter: string
  huisnummertoevoeging: string
  postcode: string

  status: string
  geconstateerd: string
  documentdatum: string
  documentnummer: string
  voorkomen: IVoorkomen

  ligIn: string
  ligtAan: string
}

export interface IPand {
  identificatie: string
  oorspronkelijkBouwjaar: string
  status: string
  geconstateerd: string
  documentdatum: string
  documentnummer: string
  voorkomen: IVoorkomen
}

export interface IVoorkomen {
  tijdstipRegistratie: string
  versie: number
  beginGeldigheid: string
  tijdstipRegistratieLV: string
  eindGeldigheid: string
}
export interface IAdresseerbaarobject {
  heeftAlsHoofdAdres: INummeraanduiding
  type: string
  geometrie: {
    punt: {
      type: string
      coordinates: Array<number>
    }
  }
  gebruiksdoelen: string
  oppervlakte: number
  status: string
  geconstateerd: string
  documentdatum: string
  documentnummer: string
  voorkomen: IVoorkomen
  maaktDeelUitVan: Array<IPand>
  heeftAlsNevenAdres: Array<INummeraanduiding>
}

const formatDate = (dateString: string): string => {
  if (!dateString) return ''

  const parsedDate = new Date(dateString)
  if (parsedDate) return parsedDate.toLocaleDateString('nl-NL')
  else return ''
}

const formatDateTime = (dateString: string): string => {
  if (!dateString) return ''

  const parsedDate = new Date(dateString)
  if (parsedDate) return parsedDate.toLocaleDateString('nl-NL') + ' ' + parsedDate.toLocaleTimeString('nl-NL')
  else return ''
}

export async function getAdresseerbaarobject(identificatie: string): Promise<IAdresseerbaarobject> {
  if (!identificatie) return Promise.reject('Identificatie is leeg')
  const url = `https://api.bag.kadaster.nl/lvbag/individuelebevragingen/v2/adresseerbareobjecten/${identificatie}?expand=true`

  const response = await fetch(url, { headers })
  if (response.ok) {
    const responseObject: IAddresseerbaarObjectResponse = await response.json()
    if (responseObject) {
      let adresseerbaarObjectToReturn: IAdresseerbaarobject = {} as IAdresseerbaarobject

      if (responseObject.verblijfsobject) {
        adresseerbaarObjectToReturn = responseObject.verblijfsobject.verblijfsobject
      }
      if (responseObject.standplaats) {
        adresseerbaarObjectToReturn = responseObject.standplaats.standplaats
      }
      if (responseObject.ligplaats) {
        adresseerbaarObjectToReturn = responseObject.ligplaats.ligplaats
      }
      adresseerbaarObjectToReturn.heeftAlsHoofdAdres = responseObject.verblijfsobject?._embedded?.heeftAlsHoofdAdres.nummeraanduiding
      adresseerbaarObjectToReturn.heeftAlsNevenAdres = responseObject.verblijfsobject?._embedded?.heeftAlsNevenAdres?.map(value => value.nummeraanduiding)
      adresseerbaarObjectToReturn.maaktDeelUitVan = responseObject.verblijfsobject?._embedded?.maaktDeelUitVan?.map(value => value.pand)
      adresseerbaarObjectToReturn.documentdatum = formatDate(adresseerbaarObjectToReturn.documentdatum)
      if (adresseerbaarObjectToReturn.voorkomen) {
        adresseerbaarObjectToReturn.voorkomen.beginGeldigheid = formatDate(adresseerbaarObjectToReturn.voorkomen.beginGeldigheid)
        adresseerbaarObjectToReturn.voorkomen.eindGeldigheid = formatDate(adresseerbaarObjectToReturn.voorkomen.eindGeldigheid)
        adresseerbaarObjectToReturn.voorkomen.tijdstipRegistratie = formatDateTime(adresseerbaarObjectToReturn.voorkomen.tijdstipRegistratie)
        adresseerbaarObjectToReturn.voorkomen.tijdstipRegistratieLV = formatDateTime(adresseerbaarObjectToReturn.voorkomen.tijdstipRegistratieLV)
      }
      return adresseerbaarObjectToReturn
    }

    return Promise.reject('Not found')
  } else {
    return Promise.reject(response.statusText)
  }
}
