import {
  Dropdown,
  IColumn,
  IDropdownOption,
  IDropdownStyles,
  IPivotItemProps,
  Link,
  mergeStyleSets,
  Pivot,
  PivotItem,
  SearchBox,
  SelectionMode,
  Stack,
  StackItem,
  Text
} from '@fluentui/react'
import FluentTable from 'components/FluentTable'
import { IVge } from 'features/vge/vgeSlice'
import { createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import { PageIdDefinition, PAGES } from 'pages'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import {
  deleteVge,
  fetchQuery,
  getFilterState,
  getLoadingState,
  getQueryState,
  getSelectedRowIndex,
  selectAll,
  setSelectedRowIndex
} from './querySlice'
import { fetchAvailableQueries, getQueriesLoadingState, selectAll as selectAllQueries } from './availableQueries'
import downloadFile from 'services/downloadFile'
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'

const classNames = mergeStyleSets({
  wrapper: {
    marginTop: 10,
    marginLeft: 10,
    height: '90vh',
    position: 'relative',
  },
  listWrapper: {
    marginTop: 10,
    marginLeft: 10,
    height: '80vh',
    width: '99%',
    position: 'absolute',
  },
})

const dropdownStyle: Partial<IDropdownStyles> = {
  dropdown: { width: 223 },
};


const VastgoedQueries = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const results = useSelector(selectAll)
  const availableQueries = useSelector(selectAllQueries)
  const loadingStatus = useSelector(getLoadingState)
  const queryState = useSelector(getQueryState)
  const queriesQueryState = useSelector(getQueriesLoadingState)
  const filterState = useSelector(getFilterState)
  const selectedRowIndex = useSelector(getSelectedRowIndex)

  const [currentFilter, setCurrentFilter] = useState<string | undefined>('')
  const [currentQuery, setCurrentQuery] = useState<IDropdownOption | undefined>()
  const [items, setItems] = useState<IVge[]>([] as IVge[])

  const uitzoeken = items.filter(x => x.srt !== "TER" && x.kpgrnt !== true && x.rel === "    ")
  const terreinen = items.filter(x => x.srt === "TER")
  const gerelateerdAan = items.filter(x => x.rel !== "    ")
  const koopgarant = items.filter(x => x.kpgrnt === true)

  const [pivotInformation, setPivotInformation] = React.useState<{ props: IPivotItemProps } | undefined>(undefined);

  const [hideDialog, setHideDialog] = useState<boolean>(true)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const handleListSelectionChanged = (ids: string[]) => setSelectedItems(ids)
  const { isReader } = useRoles()

  const onExportClicked = () => {
    if (currentQuery) {
      const uri = `/query/export/${currentQuery?.key}?filter=${currentFilter}`
      const fileName = `Export ${currentQuery?.text}`
      downloadFile(uri, fileName)
    }
  }

  const onRefreshClicked = () => {
    fetchData(currentQuery?.key.toString(), currentFilter)
  }

  const onSearch = (newValue: string | undefined) => {
    fetchData(currentQuery?.key.toString(), newValue)
  }

  const searchBoxChanged = (event, newValue) => {
    setCurrentFilter(newValue)
  }

  const onQuerySelected = (_event, item: IDropdownOption | undefined) => {
    if (item) {
      setCurrentQuery(item)
      fetchData(item.key.toString(), currentFilter)
    }
  }

  const fetchData = (query: string | undefined, filter: string | undefined) => {
    if (!query) return

    dispatch(fetchQuery({ query: query, filter: filter ?? '' }))
  }

  const removeItems = () => {
    dispatch(deleteVge(selectedItems.map(value => parseInt(value))))
    setHideDialog(true)
  }

  useEffect(() => {
    setItems(results)
  }, [results])

  const menuProps: IDropdownOption[] = availableQueries.map(value => {
    return { text: value.title, key: value.url }
  })

  useEffect(() => {
    if (queriesQueryState === 'idle') dispatch(fetchAvailableQueries())
  }, [])

  useEffect(() => {
    setCurrentFilter(filterState)
    const item = menuProps.filter(option => option.key === queryState)
    if (item.length > 0) {
      setCurrentQuery(item[0])
    }
  }, [filterState, queryState])

  const onGetKey = (item: IVge) => item?.id?.toString()

  const handleItemInvoked = (id: string, index: number) => {
    dispatch(setSelectedRowIndex(index))
    navigate(PAGES[PageIdDefinition.vge.edit].route.replace(':id', id))
  }

  const columns: IColumn[] = React.useMemo(() => {
    const cols = [
      createCustomRenderedColumn('Nr.', getPropertyName<IVge>('id'), item => <Link>{item.id}</Link>, 'S'),
      createColumn('Soort', getPropertyName<IVge>('srt'), 'S'),
      createColumn('Woonplaats', getPropertyName<IVge>('wpl')),
      createColumn('Postcode', getPropertyName<IVge>('pc')),
      createColumn('Straat', getPropertyName<IVge>('str')),
      createColumn('Huisnummer', getPropertyName<IVge>('nr')),
      createColumn('Huisletter', getPropertyName<IVge>('ltr')),
      createColumn('Toev.', getPropertyName<IVge>('nrT'), 'S'),
      createColumn('Adresaanduiding', getPropertyName<IVge>('aa'), 125),
      createCustomRenderedColumn('Verhuureenheid', getPropertyName<IVge>('vhes'), item => item[getPropertyName<IVge>('vhes')].join(', '), 125),
      createColumn('Gerelateerd', getPropertyName<IVge>('rel')),
      createCustomRenderedColumn('Koopgarant', getPropertyName<IVge>('kpgrnt'), item => item.kpgrnt ? <span>Ja</span> : <span>&nbsp;</span>),
      createColumn('Opmerking', getPropertyName<IVge>('opm'), 'XL'),
    ]

    return cols.filter(x => !(queryState === "ZonderVerhuureenheid" && getPropertyName<IVge>('vhes') === x.key))
  }, [queryState])

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { visible: isReader, text: 'Exporteren', onClick: () => { onExportClicked() }, icon: 'Export' },
    { text: 'Verversen', onClick: () => onRefreshClicked(), icon: 'Refresh' },
    { text: 'Verwijderen', onClick: () => removeItems(), icon: 'Delete', disabled: !selectedItems.length },
  ], [isReader, selectedItems.length])


  return (
    <MainTemplate title='Aansluitingscontroles' subTitle='Overzicht' commandItems={commandItems}>
      <DeleteConfirmationDialog onConfirm={removeItems} onCancel={() => setHideDialog(true)} entityName={'vastgoedeenheden'} hideDialog={hideDialog} />
      <div className={classNames.wrapper}>
        <Stack tokens={{ padding: 10, childrenGap: 10 }}>
          <StackItem>
            <Stack horizontal verticalAlign={'center'} tokens={{ padding: 10, childrenGap: 10 }}>
              <StackItem>
                <Dropdown options={menuProps} placeHolder="Kies een optie" onChange={onQuerySelected} styles={dropdownStyle} />
              </StackItem>
              <StackItem>
                <SearchBox value={currentFilter} onChange={searchBoxChanged} onSearch={onSearch} />
              </StackItem>
              <StackItem>{loadingStatus !== 'pending' && <Text variant={'medium'}>{currentQuery?.key === "ZonderVerhuureenheid" ? pivotInformation?.props.itemCount ? pivotInformation.props.itemCount : uitzoeken.length : items.length} vastgoedeenheden die voldoen aan de zoekopdracht.</Text>}</StackItem>
            </Stack>
          </StackItem>
        </Stack>
        {currentQuery?.key === 'ZonderVerhuureenheid' ?
          <Pivot onLinkClick={setPivotInformation}>
            <PivotItem key="uitzoeken" headerText="Uitzoeken" itemCount={uitzoeken.length}>
              <div className={classNames.listWrapper}>
                <FluentTable
                  columns={[
                    createCustomRenderedColumn('VGE', getPropertyName<IVge>('id'), item => <Link>{item.id}</Link>, 'S'),
                    createColumn('Soort', getPropertyName<IVge>('srt'), 'S'),
                    createColumn('Woonplaats', getPropertyName<IVge>('wpl')),
                    createColumn('Postcode', getPropertyName<IVge>('pc')),
                    createColumn('Straat', getPropertyName<IVge>('str')),
                    createColumn('Huisnummer', getPropertyName<IVge>('nr')),
                    createColumn('Huisletter', getPropertyName<IVge>('ltr')),
                    createColumn('Toevoeging', getPropertyName<IVge>('nrT')),
                    createColumn('Adresaanduiding', getPropertyName<IVge>('aa'), 125),
                    createColumn('Kadastrale aand. object', getPropertyName<IVge>('kad'), 'L'),
                    createColumn('Kadastrale aand. vlak', getPropertyName<IVge>('kadV'), 'L'),
                    createColumn('Opmerking', getPropertyName<IVge>('opm'), 'XL'),
                    createCustomRenderedColumn('Koopgarant', getPropertyName<IVge>('kpgrnt'), item => item.kpgrnt ? <span>Ja</span> : <span>&nbsp;</span>),
                    createColumn('Gerelateerd', getPropertyName<IVge>('rel')),
                  ]}
                  items={uitzoeken}
                  loading={loadingStatus === 'pending'}
                  selectionMode={SelectionMode.multiple}
                  onSelectionChanged={handleListSelectionChanged}
                  onGetKey={onGetKey}
                  onItemInvoked={handleItemInvoked}
                  scrollToRowIndex={selectedRowIndex}
                />
              </div>
            </PivotItem>
            <PivotItem key="terreinen" headerText="Terreinen" itemCount={terreinen.length}>
              <div className={classNames.listWrapper}>
                <FluentTable
                  columns={[
                    createCustomRenderedColumn('VGE', getPropertyName<IVge>('id'), item => <Link>{item.id}</Link>, 'S'),
                    createColumn('Soort', getPropertyName<IVge>('srt'), 'S'),
                    createColumn('Terreinclassificatie', getPropertyName<IVge>('trnNm'), 'XL'),
                    createColumn('Woonplaats', getPropertyName<IVge>('wpl'), 'L'),
                    createColumn('Straat', getPropertyName<IVge>('str'), 'L'),
                    createColumn('Kadastrale aand. object', getPropertyName<IVge>('kad'), 'L'),
                    createColumn('Kadastrale aand. vlak', getPropertyName<IVge>('kadV'), 'L'),
                    createColumn('Opmerking', getPropertyName<IVge>('opm'), 'XL'),
                  ]}
                  items={terreinen.sort((a, b) => {
                    if (a.wpl === b.wpl) {
                      if (a.str === b.str) {
                        return 0
                      } else if (a.str === null) {
                        return 1
                      } else if (b.str === null) {
                        return -1
                      }
                      return a.str ?? '' < (b.str ?? '') ? -1 : 1
                    } else {
                      return a.wpl ?? '' < (b.wpl ?? '') ? -1 : 1
                    }
                  })}
                  loading={loadingStatus === 'pending'}
                  selectionMode={SelectionMode.multiple}
                  onSelectionChanged={handleListSelectionChanged}
                  onGetKey={onGetKey}
                  onItemInvoked={handleItemInvoked}
                  scrollToRowIndex={selectedRowIndex}
                />
              </div>
            </PivotItem>
            <PivotItem key="gerelateerd_aan" headerText="Gerelateerd aan" itemCount={gerelateerdAan.length}>
              <div className={classNames.listWrapper}>
                <FluentTable
                  columns={[
                    createCustomRenderedColumn('VGE', getPropertyName<IVge>('id'), item => <Link>{item.id}</Link>, 'S'),
                    createColumn('Soort', getPropertyName<IVge>('srt'), 'S'),
                    createColumn('Woonplaats', getPropertyName<IVge>('wpl')),
                    createColumn('Postcode', getPropertyName<IVge>('pc')),
                    createColumn('Straat', getPropertyName<IVge>('str')),
                    createColumn('Huisnummer', getPropertyName<IVge>('nr') ),
                    createColumn('Huisletter', getPropertyName<IVge>('ltr')),
                    createColumn('Toevoeging', getPropertyName<IVge>('nrT')),
                    createColumn('Adresaanduiding', getPropertyName<IVge>('aa'), 'L'),
                    createColumn('Gerelateerd aan', getPropertyName<IVge>('rel'), 125),
                    createColumn('Kadastrale aand. object', getPropertyName<IVge>('kad'), 'L'),
                    createColumn('Kadastrale aand. vlak', getPropertyName<IVge>('kadV'), 'L'),
                    createColumn('Opmerking', getPropertyName<IVge>('opm'), 'XL'),
                  ]}
                  items={gerelateerdAan}
                  loading={loadingStatus === 'pending'}
                  selectionMode={SelectionMode.multiple}
                  onSelectionChanged={handleListSelectionChanged}
                  onGetKey={onGetKey}
                  onItemInvoked={handleItemInvoked}
                  scrollToRowIndex={selectedRowIndex}
                />
              </div>
            </PivotItem>
            <PivotItem key="koopgarant" headerText="Koopgarant" itemCount={koopgarant.length}>
              <div className={classNames.listWrapper}>
                <FluentTable
                  columns={[
                    createCustomRenderedColumn('VGE', getPropertyName<IVge>('id'), item => <Link>{item.id}</Link>),
                    createColumn('Soort', getPropertyName<IVge>('srt')),
                    createColumn('Woonplaats', getPropertyName<IVge>('wpl')),
                    createColumn('Postcode', getPropertyName<IVge>('pc')),
                    createColumn('Straat', getPropertyName<IVge>('str')),
                    createColumn('Huisnummer', getPropertyName<IVge>('nr')),
                    createColumn('Huisletter', getPropertyName<IVge>('ltr')),
                    createColumn('Toevoeging', getPropertyName<IVge>('nrT'), 125),
                    createColumn('Adresaanduiding', getPropertyName<IVge>('aa'), 125),
                    createColumn('Kadastrale aand. object', getPropertyName<IVge>('kad'), 'L'),
                    createColumn('Kadastrale aand. vlak', getPropertyName<IVge>('kadV'), 'L'),
                    createColumn('Opmerking', getPropertyName<IVge>('opm'), 'XL'),
                  ]}
                  items={koopgarant}
                  loading={loadingStatus === 'pending'}
                  selectionMode={SelectionMode.multiple}
                  onSelectionChanged={handleListSelectionChanged}
                  onGetKey={onGetKey}
                  onItemInvoked={handleItemInvoked}
                  scrollToRowIndex={selectedRowIndex}
                />
              </div>
            </PivotItem>
          </Pivot>
          : currentQuery?.key === 'ZonderKadastraleAanduiding' ?
          <div className={classNames.listWrapper}>
            <FluentTable
              columns={[
                createCustomRenderedColumn('Nr.', getPropertyName<IVge>('id'), item => <Link>{item.id}</Link>, 'S'),
                createColumn('Soort', getPropertyName<IVge>('srt'), 'S'),
                createColumn('Woonplaats', getPropertyName<IVge>('wpl')),
                createColumn('Postcode', getPropertyName<IVge>('pc')),
                createColumn('Straat', getPropertyName<IVge>('str')),
                createColumn('Huisnummer', getPropertyName<IVge>('nr')),
                createColumn('Huisletter', getPropertyName<IVge>('ltr')),
                createColumn('Toevoeging', getPropertyName<IVge>('nrT'), 125),
                createColumn('Adresaanduiding', getPropertyName<IVge>('aa'), 125),
                createCustomRenderedColumn('Verhuureenheid', getPropertyName<IVge>('vhes'), item => item[getPropertyName<IVge>('vhes')].join(', '), 125),
                createColumn('Opmerking', getPropertyName<IVge>('opm'), 'XL'),
              ]}
              items={items}
              loading={loadingStatus === 'pending'}
              selectionMode={SelectionMode.single}
              onSelectionChanged={handleListSelectionChanged}
              onGetKey={onGetKey}
              onItemInvoked={handleItemInvoked}
              scrollToRowIndex={selectedRowIndex}
            />
          </div>
          :
          <div className={classNames.listWrapper}>
            <FluentTable
              columns={columns}
              items={items}
              loading={loadingStatus === 'pending'}
              selectionMode={SelectionMode.single}
              onSelectionChanged={handleListSelectionChanged}
              onGetKey={onGetKey}
              onItemInvoked={handleItemInvoked}
              scrollToRowIndex={selectedRowIndex}
            />
          </div>
        }
      </div>
    </MainTemplate>
  )
}

export default VastgoedQueries