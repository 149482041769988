import React, { useState } from 'react'
import {
  DefaultButton,
  IButtonStyles,
  IconButton,
  IDropdownStyles,
  IStackStyles,
  PrimaryButton,
  Spinner,
  Stack
} from '@fluentui/react'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { z } from 'zod'
import { VgeMeterstandAddOrUpdateDto, VgeMeterstandDto, zodiosHooks } from '../../../../api/ApiClient'
import { useForm } from 'react-hook-form'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import VgtIncrementDecrementButton from '../../../../components/VgtIncrementDecrementButton'
import ReadOnlyField from '../../../../components/ReadOnlyField'
import VgtDropdown from '../../../../components/FluentDropdown'
import { HistoryPanel } from './historyPanel'
import { useBoolean } from '@fluentui/react-hooks'

type VgeMeterstandDtoType = z.infer<typeof VgeMeterstandDto>;
type VgeMeterstandAddOrUpdateType = z.infer<typeof VgeMeterstandAddOrUpdateDto>;

interface ISleutelsRowProps {
  item: VgeMeterstandDtoType;
  key: number;
  handleSubmissionSuccess: () => void;
}

const stackStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
    margin: '5px 0'
  }
}

const stackButtonsStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: 250
  }
}

const buttonStyles: IButtonStyles = {
  root: {
    marginLeft: 3,
    marginRight: 3,
    width: 80,
    height: 25,
    minWidth: 40
  }
}

const textFieldStyles: Partial<IDropdownStyles> = {
  root: {
    display: 'flex',
    width: '100%',
    paddingLeft: '12px'
  },
  label: {
    width: '0'
  },
  title: {
    borderWidth: '0px',
    paddingLeft: '4px'
  },
  dropdown: {
    width: '100%',
    fontWeight: 600
  }
}

const MeterstandenRow: React.FC<ISleutelsRowProps> = ({
                                                        item,
                                                        handleSubmissionSuccess
                                                      }) => {
  const [error, setError] = useState<string | undefined>()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty, errors, isSubmitting }
  } = useForm<VgeMeterstandAddOrUpdateType>({
    mode: 'all', defaultValues: item
  })

  const { data } = zodiosHooks.useGetInspectieOpnameTypes({}, { enabled: true })
  const inspectieOpnameTypeValue = watch(getPropertyName<VgeMeterstandAddOrUpdateType>('inspectieOpnameType'))

  const { mutate: addOrUpdateVgeMeterstanden, isLoading: isAddingVgeMeterstanden } = zodiosHooks.usePostVgeMeterstand(
    {}, {
      onError: (error) => setError(getTitleAndMessage(error).message),
      onSuccess: (newValue) => {
        handleSubmissionSuccess()
        reset(newValue)
        setError(undefined)
      }
    }
  )

  const { mutate: putVgeMeterstandId, isLoading: isUpdatingMeterstandId } = zodiosHooks.usePutVgeMeterstandId(
    { params: { id: item.vgeMeterstandId } }, {
      onSuccess: (newValue) => {
        handleSubmissionSuccess()
        reset(newValue)
        setError(undefined)
      },
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const options = data?.filter(item => item.naam !== null)
    .map(item => ({ key: item.id, text: item.naam! }))

  const revertChanges = () => {
    reset(item)
    setError(undefined)
  }

  const convertToNumber = (number: string | number, numberOfDecimal: number) => {
    if (!isNaN(Number(number))) {
      return parseFloat(Number(number).toFixed(numberOfDecimal))
    } else {
      return Number(number)
    }
  }

  const onSaveMeterstanden = (data: VgeMeterstandAddOrUpdateType) => {
    const combinedData = {
      ...data,
      aantal: convertToNumber(data.aantal, item.metertypeAantalDecimalen),
      vgeMeterstandId: item.vgeMeterstandId,
      vgeId: item.vgeId,
      metertypeId: item.metertypeId
    }

    if (item.vgeMeterstandId !== 0) {
      putVgeMeterstandId(combinedData)
    } else {
      addOrUpdateVgeMeterstanden(combinedData)
    }
  }

  return (
    <>
      <Stack styles={stackStyles}>
        <span style={{ width: 450, minWidth: 200 }}><ReadOnlyField title={''} value={item.metertypeNaam!}
                                                                   fieldWidth={'100%'} /></span>
        <span style={{ width: 150, minWidth: 110 }}>
            <VgtIncrementDecrementButton allowDecimal={true}
                                         name={getPropertyName<VgeMeterstandAddOrUpdateType>('aantal')}
                                         decimalPlaces={item.metertypeAantalDecimalen} control={control} errors={errors}
                                         defaultValue={item.aantal} />
          </span>
        <span style={{ width: 80, minWidth: 50 }}>
            <ReadOnlyField title={''} value={item.metertypeEenheid} fieldWidth={'100%'} />
          </span>
        <span style={{ width: 240, minWidth: 220 }}>
            <VgtDropdown styles={textFieldStyles} errors={errors}
                         name={getPropertyName<VgeMeterstandAddOrUpdateType>('inspectieOpnameType')}
                         defaultSelectedKey={item.inspectieOpnameType} required={true} label={''} options={options}
                         control={control} />
          </span>
        <span style={{ width: 80, textAlign: 'center' }}>
            <IconButton onClick={() => {
              openPanel()
            }} iconProps={{ iconName: 'History' }} disabled={item.vgeMeterstandId === 0} />
          </span>
        <Stack styles={stackButtonsStyles}>
          <PrimaryButton styles={buttonStyles} type='submit' onClick={handleSubmit(onSaveMeterstanden)}
                         disabled={inspectieOpnameTypeValue === 0 || isSubmitting || isAddingVgeMeterstanden || isUpdatingMeterstandId}
                         text='Opslaan'>
            {isSubmitting ?
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Spinner />
              </div>
              : null}
          </PrimaryButton>
          {isDirty && <DefaultButton styles={buttonStyles} onClick={revertChanges}>Annuleren</DefaultButton>}
        </Stack>
      </Stack>
      {item.vgeMeterstandId !== 0 &&
        <HistoryPanel isOpen={isPanelOpen} dismissPanel={dismissPanel} vgeMeterstandId={item.vgeMeterstandId} />}
      <ErrorMessageBar error={error} />
    </>
  )
}

export default MeterstandenRow
