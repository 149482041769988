import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { VgtPagedTable } from 'components/VgtPagedTable/VgtPagedTable'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePagedParams } from 'services/usePagedParams'
import { useBoolean } from '@fluentui/react-hooks'
import { TenantTaakListDto, zodiosHooks } from 'api/ApiClient'
import { IColumn} from '@fluentui/react'
import { createBooleanColumn, createColumn} from 'lib/gridHelper'
import { z } from 'zod'
import { TenantTaakFormPanel } from './TenantTaakFormPanel'
import { getPropertyName } from 'lib/interfaceUtils'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'

type TenantTaakListDtoType = z.infer<typeof TenantTaakListDto>

export const TenantTaakList = () => {

  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [itemToEdit, setItemToEdit] = useState<number>(0)
  const [taakId, setTaakId] = useState<number>(0)
  const [error, setError] = React.useState<string>()

  const { data, invalidate, isLoading, error: apiError } = zodiosHooks.useGetTenantTaak({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  React.useEffect(() => {
    if (apiError) {
      setError(apiError.message || 'Er is een fout opgetreden.')
    } else {
      setError(undefined)
    }
  }, [apiError])

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' }
  ], [navigate, invalidate])

  const handleItemInvoked = (id: number | null, taakId: number) => {
    if (id) {
      setItemToEdit(id)
    }
    setTaakId(taakId)
    openPanel()
  }

  const handleDismissPanel = () => {
    setItemToEdit(0)
    dismissPanel()
  }

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('TaakNr', getPropertyName<TenantTaakListDtoType>('taakId'), 'M'),
      createColumn('Taak', getPropertyName<TenantTaakListDtoType>('naam'), 'MAX', true),
      createColumn('Binnen/Buiten', getPropertyName<TenantTaakListDtoType>('onderhoudslocatieOptieNaam'), 'M', true),
      createBooleanColumn('Actief', getPropertyName<TenantTaakListDtoType>('actief')),
      createBooleanColumn('Tonen aan klant', getPropertyName<TenantTaakListDtoType>('tonenAanKlant'), 'L'),
      createBooleanColumn('Afspraak', getPropertyName<TenantTaakListDtoType>('afspraak'), 'M'),
      createBooleanColumn('Vereist inspectie', getPropertyName<TenantTaakListDtoType>('inspectieVereist'), 'L'),
      createBooleanColumn('Onderhoudscontract', getPropertyName<TenantTaakListDtoType>('metOnderhoudscontract'), 'L'),
      createBooleanColumn('Geen service', getPropertyName<TenantTaakListDtoType>('zelfOplossen'), 'L'),
      createColumn('Betaler', getPropertyName<TenantTaakListDtoType>('betalerNaam'), 'L'),
      createColumn('Onderhoudstaak ERP', getPropertyName<TenantTaakListDtoType>('onderhoudstaakErp'), 'L')
    ]
  }, [])

  return (
    <MainTemplate title="Inrichten niet-geïnspecteerde taken" subTitle="overzicht" commandItems={commandItems} error={error}>
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
    <TenantTaakFormPanel tenantTaakId={itemToEdit} taakId={taakId} dismissPanel={handleDismissPanel} isOpen={isPanelOpen} invalidate={invalidate} />
      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage || false,
          hasPreviousPage: data?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.taakId}
        onItemInvoked={(e) => handleItemInvoked(e.tenantTaakId, e.taakId)}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />

    </MainTemplate>
  )
}