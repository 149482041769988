import { CommandBar, IColumn, ICommandBarItemProps, Link, Pivot, PivotItem, SelectionMode } from '@fluentui/react'
import { PageIdDefinition, PAGES } from 'pages'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getLoadingState,
  getPresentationType,
  ISearchParams,
  IVge,
  selectFilteredEntities,
  setPresentationType,
  setSearchFilter
} from './vgeSlice'
import GoogleMapReact from 'google-map-react'
import FluentTable from 'components/FluentTable'
import { createBooleanColumn, createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import commandBarStyles from '../../styles/commandBarStyles'
import { createCommandButton } from '../../lib/commandBarHelper'
import { VgeHistorischPanel } from './VgeHistorischPanel'
import { useBoolean } from '@fluentui/react-hooks'

const SearchResults: React.FC = () => {
  const { query, type, locatedIn } = useParams()
  const items = useSelector(selectFilteredEntities)
  const presentationType = useSelector(getPresentationType)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loadingState = useSelector(getLoadingState)
  const onGetKey = (item: IVge) => item.id.toString()
  const [getSelectedIds, setSelectedIds] = useState([] as number[])

  useMemo(() => {
    dispatch(setSearchFilter({ text: query, type: type, locatedIn: locatedIn } as ISearchParams))
  }, [query, type, locatedIn])

  const onPivotItemSelect = pivotItem => {
    dispatch(setPresentationType(pivotItem.props.itemKey))
  }

  const handleItemInvoked = (id: string) => {
    navigate(PAGES[PageIdDefinition.vge.edit].route.replace(':id', id))
  }

  const MarkerComponent = ({ text, item }: { text: string, item: IVge, lat: number, lng: number }) => {
    return (
      <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
        <div className='pin'>{text}</div>
      </Link>
    )
  }

  const calculateCenter = () => {
    if (items && items.length > 0) {
      const { lat, long } = items[0]
      if (lat !== undefined && long !== undefined) {
        return { lat, lng: long } as GoogleMapReact.Coords
      }
    }
    return undefined
  }

  const calculateZoom = () => {
    if (!items || items.length === 0) return 6

    const latitudes = items.map(item => item.lat).filter((lat): lat is number => lat !== undefined)
    const longitudes = items.map(item => item.long).filter((long): long is number => long !== undefined)

    if (latitudes.length === 0 || longitudes.length === 0) {
      return 6
    }
    return 15
  }

  const createVgeSearchColumns = () => {
    return [
      createCustomRenderedColumn('Woonplaats', getPropertyName<IVge>('wpl'), (item: IVge) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>{item.wpl}</Link>
      ), 'L', false),
      createColumn('Wijk', getPropertyName<IVge>('wk'), 'L'),
      createColumn('Straat', getPropertyName<IVge>('str'), 'XL'),
      createColumn('Huisnummer', getPropertyName<IVge>('nr'), 'M', true),
      createColumn('Huislet.', getPropertyName<IVge>('ltr'), 'S'),
      createColumn('Toev.', getPropertyName<IVge>('nrT'), 'S'),
      createColumn('Adresaanduiding', getPropertyName<IVge>('aa'), 'M'),
      createColumn('Terreinclassificatie', getPropertyName<IVge>('trnNm'), 'M'),
      createColumn('VGE Type', getPropertyName<IVge>('type'), 'M'),
      createColumn('Eenheiddetailsoort', getPropertyName<IVge>('srtNm'), 'L'),
      createColumn('Verhuureenheid', getPropertyName<IVge>('vhes'), 'XXL', false, (item: IVge) => item.vhes ? item.vhes.join(', ') : ''),
      createBooleanColumn('Historisch', getPropertyName<IVge>('hist'), 'M')
    ] as IColumn[]
  }

  const iconListRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(400)
  useEffect(() => {
    const handleResize = () => {
      const headersHeight = 60
      const availableHeight = window.innerHeight - headersHeight

      if (iconListRef.current) {
        setHeight(availableHeight - iconListRef.current.offsetTop)
      } else {
        setHeight(availableHeight - 300)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const columns = useMemo(() => createVgeSearchColumns(), [])

  const [inHistorischMode, { setTrue: openHistorischPanel, setFalse: dismissHistorischPanel }] = useBoolean(false)
  const [historischButton, { setTrue: showHistorischButton, setFalse: hideHistorischButton }] = useBoolean(false)
  const selectCommandBarItems: ICommandBarItemProps[] = useMemo(
    () => [createCommandButton('Historisch maken', 'Edit', openHistorischPanel, historischButton)],
    [historischButton]
  )
  const onSelectionChanged = (ids: string[]) => {
    const selectedIds = ids.map(id => parseInt(id))
    setSelectedIds(selectedIds)
    selectedIds.length > 0 ? showHistorischButton() : hideHistorischButton()
  }
  const onModified = () => {
    dismissHistorischPanel()
  }
  return (
    <div style={{ margin: 10 }}>
      <VgeHistorischPanel onModified={onModified} dismissPanel={dismissHistorischPanel} isOpen={inHistorischMode}
                          vgeIds={getSelectedIds} />
      <h1>{items.length} resultaten
        voor {type ?? ''} '{query}' {locatedIn !== undefined ? ' in ' : ''}{locatedIn}</h1>
      <Pivot selectedKey={presentationType} onLinkClick={onPivotItemSelect}>
        <PivotItem itemKey='list' headerText='Lijst' itemIcon='List'>
          <CommandBar items={selectCommandBarItems} styles={commandBarStyles} />
          <div ref={iconListRef} style={{ height: `${height}px`, overflow: 'auto' }}>
            <FluentTable
              columns={columns}
              items={items}
              loading={loadingState === 'pending'}
              selectionMode={SelectionMode.multiple}
              onSelectionChanged={onSelectionChanged}
              onGetKey={onGetKey}
              onItemInvoked={handleItemInvoked} />
          </div>
        </PivotItem>
        <PivotItem itemKey='map' headerText='Kaart' itemIcon='Globe'>
          <div ref={iconListRef} style={{ height: `${height}px`, overflow: 'auto' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyATEnt3CXMpsDjdPvlXpUPaDapGig_qr6M', language: 'nl-NL' }}
              defaultCenter={{ lat: 52.0907374, lng: 5.1214201 }}
              defaultZoom={6}
              options={{ mapTypeControl: true, streetViewControl: true }}
              center={calculateCenter()}
              zoom={calculateZoom()}
            >
              {items.map(adres => (
                <MarkerComponent key={adres.id} lat={adres.lat ?? 51.72835975} lng={adres.long ?? 5.01544399}
                                 text={adres.nr ?? adres.aa ?? ''} item={adres} />
              ))}
            </GoogleMapReact>
          </div>
        </PivotItem>
      </Pivot>
    </div>
  )
}

export default SearchResults
