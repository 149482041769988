import React, { useCallback, useMemo, useState } from 'react'
import {
  Checkbox,
  DetailsList,
  IButtonStyles,
  ICheckboxStyles,
  IconButton,
  IDetailsListProps,
  IStackStyles,
  Link,
  mergeStyleSets,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Text
} from '@fluentui/react'
import { z } from 'zod'
import {
  ElementElementDetailListDto,
  GerelateerdInspectietakenListDto,
  GerelateerdReparatieboomTaken
} from '../../../../api/ApiClient'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { createCustomRenderedColumn } from '../../../../lib/gridHelper'
import { DetailsHeader } from '@fluentui/react/lib/DetailsList'

type ElementElementDetailListDtoType = z.infer<typeof ElementElementDetailListDto>;
type GerelateerdInspectietakenListDtoType = z.infer<typeof GerelateerdInspectietakenListDto>;
type GerelateerdReparatieboomTakenType = z.infer<typeof GerelateerdReparatieboomTaken>;

interface IElementElementDetailProps {
  item: ElementElementDetailListDtoType;
  index: number;
  handleItemSelectionChanged: (item: number) => void;
  setSelectedElementElementDetailspage: (item: ElementElementDetailListDtoType) => void;
  fetchElementElementDetailspageItems: () => void;
  openEditPanel: () => void;
}

const checkBoxStyles: Partial<ICheckboxStyles> = {
  checkbox: { borderRadius: '50%' }
}

const iconButtonStyles: Partial<IButtonStyles> = {
  root: { width: 60, height: 'auto' }
}

const classNames = mergeStyleSets({
  rowStyles: {
    width: 150,
    maxWidth: 200,
    overflowX: 'auto'
  },
  lastColumn: {
    width: '200px',
    paddingLeft: 4,
    '@media (min-width: 1920px)': {
      minWidth: '230px'
    }
  }
})

const ElementElementDetailRow: React.FC<IElementElementDetailProps> = ({
                                                                         item,
                                                                         handleItemSelectionChanged,
                                                                         index,
                                                                         openEditPanel,
                                                                         setSelectedElementElementDetailspage
                                                                       }) => {
  const [showGerelateerdInspectietakenTable, setShowGerelateerdInspectietakenListTable] = useState<boolean>(false)
  const [showGerelateerdReparatieboomTakenTable, setShowGerelateerdReparatieboomTakenTable] = useState<boolean>(false)
  const [itemChecked, setItemChecked] = useState<boolean>(false)
  const [showAddRemoveButton, setShowAddRemoveButton] = useState<boolean>(false)

  const {
    gerelateerdInspectietakenListDto,
    gerelateerdReparatieboomTaken,
    id,
    element,
    elementDetail,
    materiaalSoortNaam,
    nlSfBCode,
    nlSfBOmschrijving
  } = item

  const elementElementDetailStackStyles: IStackStyles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '20px',
      backgroundColor: index % 2 === 0 ? '#f7f7f7' : '#ffffff',
      '&:hover': { backgroundColor: '#f3f3f3' }
    }
  }

  const itemStackStyles: IStackStyles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '20px'
    }
  }

  const handleItemInvoked = useCallback(() => {
    setSelectedElementElementDetailspage(item)
    openEditPanel()
  }, [item, openEditPanel, setSelectedElementElementDetailspage])

  const gerelateerdInspectietakenColumns = useMemo(() => [
    createCustomRenderedColumn(
      'Nr.',
      getPropertyName<GerelateerdInspectietakenListDtoType>('inspectietaakId'),
      (item: GerelateerdInspectietakenListDtoType) => <span style={{ fontSize: 14 }}>{item.inspectietaakId}</span>,
      'M',
      false
    ),
    createCustomRenderedColumn(
      'Inspectietaak',
      getPropertyName<GerelateerdInspectietakenListDtoType>('taak'),
      (item: GerelateerdInspectietakenListDtoType) => <span style={{ fontSize: 14 }}>{item.taak}</span>,
      'MAX',
      false
    )
  ], [])

  const reparatieboomColumns = useMemo(() => [
    createCustomRenderedColumn(
      'Nr.',
      getPropertyName<GerelateerdReparatieboomTakenType>('taakId'),
      (item: GerelateerdReparatieboomTakenType) => <span style={{ fontSize: 14 }}>{item.taakId}</span>,
      'M',
      false
    ),
    createCustomRenderedColumn(
      'Reparatieboom-taak',
      getPropertyName<GerelateerdReparatieboomTakenType>('naam'),
      (item: GerelateerdReparatieboomTakenType) => <span style={{ fontSize: 14 }}>{item.naam}</span>,
      'MAX',
      false
    )
  ], [])

  const onCheckboxChange = useCallback(() => {
    setItemChecked(!itemChecked)
    handleItemSelectionChanged(id)
  }, [itemChecked, id, handleItemSelectionChanged])

  const showTable = () => {
    const {
      gerelateerdInspectietakenListDto,
      gerelateerdReparatieboomTaken
    } = item || {}

    const hasInspectieTaken = gerelateerdInspectietakenListDto?.length! > 0
    const hasReparatieboomTaken = gerelateerdReparatieboomTaken?.length! > 0

    if (hasInspectieTaken && hasReparatieboomTaken) {
      setShowGerelateerdInspectietakenListTable((prev) => !prev)
      setShowGerelateerdReparatieboomTakenTable((prev) => !prev)
    } else if (hasReparatieboomTaken) {
      setShowGerelateerdReparatieboomTakenTable((prev) => !prev)
    } else if (hasInspectieTaken) {
      setShowGerelateerdInspectietakenListTable((prev) => !prev)
    }
    setShowAddRemoveButton((prev) => !prev)
  }

  const _onRenderDetailsHeader: IDetailsListProps['onRenderDetailsHeader'] = useCallback((props) => {
    if (props) {
      return <DetailsHeader {...props} ariaLabelForToggleAllGroupsButton={'Toggle selection'}
                            styles={{ root: { fontWeight: 700 } }} />
    }
    return null
  }, [])

  return (
    <>
      <Stack styles={elementElementDetailStackStyles}>
        {(gerelateerdInspectietakenListDto?.length! || gerelateerdReparatieboomTaken?.length!) > 0 ? (
          <IconButton iconProps={{ iconName: showAddRemoveButton ? 'Remove' : 'Add' }}
                      onClick={showTable} styles={iconButtonStyles} />
        ) : (
          <Stack style={{ width: 60 }} />
        )}
        <Checkbox checked={itemChecked} onChange={onCheckboxChange} styles={checkBoxStyles} />
        <Stack onClick={() => handleItemSelectionChanged(id)} styles={itemStackStyles}>
          <Text style={{ width: 100, maxWidth: 150 }}>{id}</Text>
          <Link onClick={handleItemInvoked} className={classNames.rowStyles}>{element}</Link>
          <Link onClick={handleItemInvoked} className={classNames.rowStyles}>{elementDetail}</Link>
          <Text style={{ width: 150, maxWidth: 150, paddingLeft: 3 }}>{materiaalSoortNaam ?? ''}</Text>
          <Text className={classNames.rowStyles}>{nlSfBCode ?? ''}</Text>
          <Text className={classNames.lastColumn}>{nlSfBOmschrijving ?? ''}</Text>
        </Stack>
      </Stack>
      {showGerelateerdInspectietakenTable && (
        gerelateerdInspectietakenListDto?.length ? (
          <div style={{ marginLeft: 110 }}>
            <DetailsList
              compact
              columns={gerelateerdInspectietakenColumns}
              styles={{ root: { fontSize: '14px' } }}
              items={gerelateerdInspectietakenListDto}
              selectionMode={SelectionMode.none}
              onRenderDetailsHeader={_onRenderDetailsHeader}
            />
          </div>
        ) : <Spinner size={SpinnerSize.large} />
      )}
      {showGerelateerdReparatieboomTakenTable && (
        gerelateerdReparatieboomTaken?.length ? (
          <div style={{ marginLeft: 110 }}>
            <DetailsList
              compact
              columns={reparatieboomColumns}
              styles={{ root: { fontSize: '14px' } }}
              items={gerelateerdReparatieboomTaken}
              selectionMode={SelectionMode.none}
              onRenderDetailsHeader={_onRenderDetailsHeader}
            />
          </div>
        ) : <Spinner size={SpinnerSize.large} />
      )}
    </>
  )
}

export default ElementElementDetailRow
