import FluentPanel from '../../components/FluentPanel/FluentPanel'
import { z } from 'zod'
import { VgeSelectionListDto, VheAddDto, zodiosHooks } from '../../api/ApiClient'
import React, { useEffect, useState } from 'react'
import { getTitleAndMessage } from '../../services/HandleError'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ChoiceGroup, DefaultButton, IChoiceGroupOption, IColumn, PanelType, PrimaryButton, SearchBox, Selection, SelectionMode, ShimmeredDetailsList, Spinner, Stack } from '@fluentui/react'
import VgtTextField from '../../components/VgtTextField'
import { getPropertyName } from '../../lib/interfaceUtils'
import VgtDropdown from '../../components/FluentDropdown'
import FluentToggle from '../../components/FluentToggle'
import VgtCheckbox from '../../components/FluentCheckbox'

import { WizardStepper } from './WizardStepper'
import { createColumn } from '../../lib/gridHelper'

export interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: () => void
  invalidate: () => void
}

export type AddVheType = z.infer<typeof VheAddDto>;
type VheListDtoType = z.infer<typeof VgeSelectionListDto>;

enum VheAddWizardSteps {
  selectType,
  selectVge,
  selectAdres = 2,
  editProperties = 3
}

enum VgeType {
  EenOpEen = 2,
  Gesplitst = 3,
  Gegroepeerd = 4
}

const defaultVheValues = {
  eenheiddetailsoortId: 0,
  waardestelselOptieId: 0,
  isAdresOverschreven: false,
  code: '',
  zorgwoning: false
}

export const VheNewPanel: React.FC<IEditPanelProps> = props => {
  const [error, setError] = React.useState<string>()
  const {
    control,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors }
  } = useForm<AddVheType>({
    resolver: zodResolver(VheAddDto),
    mode: 'all',
    defaultValues: defaultVheValues
  })

  const { data: systemSettings } = zodiosHooks.useGetSysteemInstellingen()
  const { mutate, isLoading: isModifying } = zodiosHooks.usePostVhes({}, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  useEffect(() => {
    if (props.isOpen) {
      setError(undefined)
      setCurrentStep(0)
      setVgeType(undefined)
      if (systemSettings?.vheNummerActief === true) {
        reset({ ...defaultVheValues, code: 'Gegenereerd' })
      } else {
        reset(defaultVheValues)
      }
    }
  }, [props.isOpen])

  const onCancel = () => {
    props.invalidate()
    reset({})
    setError(undefined)
    props.dismissPanel()
  }

  const onSubmit = (data: AddVheType) => {
    setError(undefined)

    mutate(data)
  }

  const [currentStep, setCurrentStep] = useState<VheAddWizardSteps>(VheAddWizardSteps.selectType)
  const [vgeType, setVgeType] = useState<VgeType | undefined>(undefined)
  const [selectedVges, setSelectedVges] = useState<VheListDtoType[]>([])

  const onTypeSelected = (vgeType: VgeType) => {
    setVgeType(vgeType)
    switch (vgeType) {
      case VgeType.EenOpEen:
        setValue(getPropertyName<AddVheType>('waardestelselOptieId'), 2) // Zelfstandig
        break
      case VgeType.Gesplitst:
        setValue(getPropertyName<AddVheType>('waardestelselOptieId'), 3) // Onzelfstandig
        break
      case VgeType.Gegroepeerd:
        setValue(getPropertyName<AddVheType>('waardestelselOptieId'), 0) // Onbekend
        break
    }
    setCurrentStep(VheAddWizardSteps.selectVge)
  }

  const onVgesSelected = (vges: VheListDtoType[]) => {
    setSelectedVges(vges)

    const vge = vges[0]
    setValue(getPropertyName<AddVheType>('adresaanduiding'), '')
    setValue(getPropertyName<AddVheType>('isAdresOverschreven'), false)
    setValue(getPropertyName<AddVheType>('adres'), vge.adres)
    setValue(getPropertyName<AddVheType>('postcode'), vge.postcode)
    setValue(getPropertyName<AddVheType>('woonplaats'), vge.woonplaats)
    setValue(getPropertyName<AddVheType>('eenheiddetailsoortId'), vge.eenheiddetailsoortId)

    setCurrentStep(VheAddWizardSteps.selectAdres)
  }

  const onAdresSelected = () => {
    setCurrentStep(VheAddWizardSteps.editProperties)
  }

  const onFinish = () => {
    const data = {...getValues(), vgeIds: selectedVges.map(vge => vge.id)}
    console.log(data)
    onSubmit(data)
    // handleSubmit(onSubmit, (errors) => setError(FieldErrorsToMessage(errors)))
  }

  const onPrevious = () => {
    if (currentStep === 0) return
    setCurrentStep(currentStep - 1)
  }

  const handleStepClick = (step: number) => {
    if (step < currentStep) { // Only allow clicking previous steps
      setCurrentStep(step);
    }
  };

  return (
    <FluentPanel headerText={'Toevoegen verhuureenheid'} type={PanelType.large} isOpen={props.isOpen} onDismiss={onCancel} error={error}>

      <WizardStepper onStepClick={handleStepClick} currentStep={currentStep} steps={[{ title: 'Type selectie' }, { title: 'Selecteren vge(s)' }, { title: 'Bepalen adres' }, { title: 'Eigenschappen' }]} />

      {currentStep === VheAddWizardSteps.selectType && <SelectType currentVgeType={vgeType} onNext={onTypeSelected} onCancel={onCancel} />}

      <SelectVge show={currentStep === VheAddWizardSteps.selectVge} onNext={onVgesSelected} vgeType={vgeType} onPrevious={onPrevious} />

      <SelectAdres show={currentStep === VheAddWizardSteps.selectAdres} vgeType={vgeType ?? VgeType.EenOpEen} control={control} errors={errors} watch={watch} onNext={onAdresSelected} onPrevious={onPrevious} />

      <EditProperties show={currentStep === VheAddWizardSteps.editProperties} isModifying={isModifying} autoNummering={systemSettings?.vheNummerActief} onNext={onFinish} onPrevious={onPrevious} control={control} errors={errors} />

      <FluentPanel.Footer>
        <Stack horizontal wrap horizontalAlign={'end'} >
          <PrimaryButton text="Annuleren" onClick={onCancel} />
        </Stack>
      </FluentPanel.Footer>
    </FluentPanel>
  )
}

interface ISelectTypeProps {
  currentVgeType?: VgeType
  onNext: (vgeType: VgeType) => void
  onCancel: () => void
}

const SelectType: React.FC<ISelectTypeProps> = ({ currentVgeType, onNext, onCancel }) => {
  const [selectedType, setSelectedType] = React.useState<VgeType | undefined>(undefined)
  const computedSelectedType = selectedType ?? currentVgeType

  useEffect(() => {
    if (currentVgeType !== undefined && selectedType === undefined) {
      setSelectedType(currentVgeType)
    }
  }, [currentVgeType])

  const options: IChoiceGroupOption[] = [
    {
      key: VgeType.EenOpEen.toString(),
      text: 'Één op één',
    },
    {
      key: VgeType.Gesplitst.toString(),
      text: 'Gesplitst',
    },
    {
      key: VgeType.Gegroepeerd.toString(),
      text: 'Gegroepeerd',
    }
  ]

  const handleChange = (_?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    if (option) {
      setSelectedType(Number(option.key) as VgeType)
    }
  }

  const handleNext = () => {
    if (computedSelectedType !== undefined) {
      onNext(computedSelectedType)
    }
  }

  return (
    <Stack tokens={{ childrenGap: 20}}>
      <div style={{ padding: 20 }}>
        <ChoiceGroup
          label="Selecteer het type verhuureenheid"
          options={options}
          onChange={handleChange}
          required={true}
          selectedKey={computedSelectedType?.toString()}
        />
      </div>
      <Stack.Item styles={{
        root: {
          paddingTop: 20,
          borderTop: '1px solid #edebe9',
          marginTop: 'auto'
        }
      }}>
        <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
          <PrimaryButton
            text="Volgende"
            onClick={handleNext}
            disabled={computedSelectedType === undefined}
          />
        </Stack>
      </Stack.Item>
    </Stack>
  )
}

interface ISelectVgeProps {
  show: boolean;
  vgeType?: VgeType
  onNext: (vges: VheListDtoType[]) => void
  onPrevious: () => void
}

const SelectVge: React.FC<ISelectVgeProps> = ({ show, vgeType, onNext, onPrevious }) => {
  const [selection] = useState(new Selection({
    selectionMode: vgeType === VgeType.EenOpEen ? SelectionMode.single : SelectionMode.multiple,
    onSelectionChanged: () => {
      const selectedItems = selection.getSelection() as VheListDtoType[]
      setSelectedVges(selectedItems)
    }
  }))

  const [selectedVges, setSelectedVges] = React.useState<VheListDtoType[]>([])
  const handleNext = () => {
    if (selectedVges.length !== 0) {
      onNext(selectedVges)
    }
  }

  const [filter, setFilter] = useState('')

  const { data: vges, isLoading } = zodiosHooks.useGetVgeforVheSelection(
    { queries: { VgeType: vgeType ?? 0, Filter: filter } },
    { enabled: vgeType !== undefined }
  )

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Adres', getPropertyName<VheListDtoType>('adres'), 'XL', true),
      createColumn('Postcode', getPropertyName<VheListDtoType>('postcode'), 'M', false),
      createColumn('Woonplaats', getPropertyName<VheListDtoType>('woonplaats'), 'L', false),
      createColumn('Eenheiddetailsoort', getPropertyName<VheListDtoType>('eenheiddetailsoortNaam'), 'L', false)
    ]
  }, [])


  return (
    <Stack styles={{
      root: {
        height: 'calc(100vh - 300px)', // Account for panel header, wizard stepper, and buttons
        display: show ? 'flex' : 'none',
        flexDirection: 'column',
        marginBottom: 20
      }
    }}>
      <Stack.Item grow styles={{
        root: {
          height: 0, // This forces the item to grow within the available space
          padding: '20px',
          overflow: 'auto'
        }
      }}>
        <SearchBox placeholder="Zoeken ..." styles={{ root: { width: '100%'  } }}
                  value={filter}
                  onChange={(event, newValue) => setFilter(newValue ?? '')}
                  onSearch={(newValue: string) => setFilter(newValue ?? '')}
        />

        <ShimmeredDetailsList
          columns={columns}
          items={vges ?? []}
          selection={selection}
          selectionMode={vgeType === VgeType.EenOpEen ? SelectionMode.single : SelectionMode.multiple}
          shimmerLines={10}
          enableShimmer={isLoading}
        />

      </Stack.Item>

      <Stack.Item styles={{
        root: {
          paddingTop: 20,
          borderTop: '1px solid #edebe9',
          marginTop: 'auto' // Push to bottom
        }
      }}>
        <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
          <PrimaryButton
            text="Vorige"
            onClick={onPrevious}
          />
          <PrimaryButton
            text="Volgende"
            onClick={handleNext}
            disabled={selectedVges.length === 0}
          />
        </Stack>
      </Stack.Item>
    </Stack>
  )
}

interface ISelectAdresProps {
  show: boolean;
  vgeType: VgeType
  control: any
  errors: any
  watch: any
  onNext: () => void
  onPrevious: () => void
}

const SelectAdres: React.FC<ISelectAdresProps> = ({ show, vgeType, control, errors, watch, onNext, onPrevious }) => {
  const isOverschreven = watch(getPropertyName<AddVheType>('isAdresOverschreven'))

  return (
    <Stack tokens={{ childrenGap: 20, padding: 0 }} styles={{ root: { display: show ? 'flex' : 'none' } }}>
      <Stack.Item tokens={{ padding: 20 }}>

        <VgtCheckbox showIf={vgeType !== VgeType.EenOpEen} name={getPropertyName<AddVheType>('isAdresOverschreven')} label={isOverschreven ? 'Adres handmatig overschreven' : 'Adres handmatig invoeren'} control={control} errors={errors} />
        <VgtTextField readOnly={!isOverschreven} label="Adres" name={getPropertyName<AddVheType>('adres')} control={control} errors={errors} />
        <VgtTextField readOnly={!isOverschreven} label="Postcode" name={getPropertyName<AddVheType>('postcode')} pattern={'[1-9][0-9]{3}[a-zA-Z]{2}'} control={control} errors={errors} />
        <VgtTextField readOnly={!isOverschreven} label="Woonplaats" name={getPropertyName<AddVheType>('woonplaats')} control={control} errors={errors} />
        <VgtTextField label="Adresaanduiding" name={getPropertyName<AddVheType>('adresaanduiding')} control={control} errors={errors} />
      </Stack.Item>
      <Stack.Item styles={{
        root: {
          paddingTop: 20,
          borderTop: '1px solid #edebe9',
          marginTop: 'auto' // Push to bottom
        }
      }}>
        <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
          <PrimaryButton
            text="Vorige"
            onClick={onPrevious}
          />
          <PrimaryButton
            text="Volgende"
            onClick={onNext}
          />
        </Stack>
      </Stack.Item>
    </Stack>
  )
}

interface IEditPropertiesProps {
  isModifying: boolean
  show: boolean;
  control: any
  errors: any

  autoNummering?: boolean;
  onNext: () => void
  onPrevious: () => void
}

const EditProperties: React.FC<IEditPropertiesProps> = ({ show, isModifying, control, errors, onNext, onPrevious, autoNummering }) => {
  const { data: eenheiddetailsoortOpties } = zodiosHooks.useGetEenheidDetailSoortdropdownoptions({}, { enabled: true })
  const { data: waardestelselOpties } = zodiosHooks.useGetVheswaardestelselOpties({}, { enabled: true })

  return (
    <Stack tokens={{ childrenGap: 20, padding: 0 }} styles={{ root: { display: show ? 'flex' : 'none' } }}>
      <Stack.Item tokens={{ padding: 20 }}>
        <VgtTextField label="VHE Nummer" name={getPropertyName<AddVheType>('code')} control={control} errors={errors} readOnly={autoNummering === true} required={true} />
        <VgtDropdown label={'Eenheiddetailsoort'} name={getPropertyName<AddVheType>('eenheiddetailsoortId')} control={control} options={eenheiddetailsoortOpties} required={true} errors={errors} />
        <VgtDropdown label={'Waarderingsstelsel'} name={getPropertyName<AddVheType>('waardestelselOptieId')} control={control} options={waardestelselOpties} errors={errors} required={true} />
        <FluentToggle label={'Zorgwoning'} inlineLabel name={getPropertyName<AddVheType>('zorgwoning')} control={control} errors={errors} />
      </Stack.Item>
      <Stack.Item styles={{
        root: {
          paddingTop: 20,
          borderTop: '1px solid #edebe9',
          marginTop: 'auto' // Push to bottom
        }
      }}>
        <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
          <PrimaryButton
            text="Vorige"
            onClick={onPrevious}
          />
          <DefaultButton text={'Opslaan'} primary disabled={isModifying} onClick={onNext} style={{ position: 'relative' }}>
            {isModifying ?
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Spinner />
              </div>
              : null}
          </DefaultButton>
        </Stack>
      </Stack.Item>
    </Stack>
  )
}
