import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import InputCard from '../../components/InputCard'
import ReadOnlyField from '../../components/ReadOnlyField'
import { zodiosHooks } from '../../api/ApiClient'
import { Stack, StackItem } from '@fluentui/react'
import { VheNummeringPanel } from './VheNummeringPanel'
import { useBoolean } from '@fluentui/react-hooks'
import { CrmPanel } from './CrmPanel'
import { FieldServicePanel } from './FieldServicePanel'
import { IrisFieldServicePanel } from './IrisFieldServicePanel'
import { TenantPanel } from './TenantPanel'
import { VerhuurPanel } from './VerhuurPanel'
import React from 'react'
import DeleteConfirmationDialog from '../../components/DeleteConfirmationDialog'
import { getTitleAndMessage } from '../../services/HandleError'
import { CrmSecretPanel } from './CrmSecretPanel'


export const SystemSettingsView = () => {
  const commandItems = []
  const [error, setError] = React.useState<string>()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [isCrmPanelOpen, { setTrue: openCrmPanel, setFalse: dismissCrmPanel }] = useBoolean(false)
  const [isCrmSecretPanelOpen, { setTrue: openCrmSecretPanel, setFalse: dismissCrmSecretPanel }] = useBoolean(false)
  const [isFieldServicePanelOpen, { setTrue: openFieldServicePanel, setFalse: dismissFieldServicePanel }] = useBoolean(false)
  const [isIrisFieldServicePanelOpen, { setTrue: openIrisFieldServicePanel, setFalse: dismissIrisFieldServicePanel }] = useBoolean(false)
  const [isTenantPanelOpen, { setTrue: openTenantPanel, setFalse: dismissTenantPanel }] = useBoolean(false)
  const [isVerhuurPanelOpen, { setTrue: openVerhuurPanel, setFalse: dismissVerhuurPanel }] = useBoolean(false)
  const [isFieldServiceDeleteDialogOpen, { setTrue: openFieldServiceDeleteDialog, setFalse: closeFieldServiceDeleteDialog }] = useBoolean(false);
  const [isIrisFieldServiceDeleteDialogOpen, { setTrue: openIrisFieldServiceDeleteDialog, setFalse: closeIrisFieldServiceDeleteDialog }] = useBoolean(false);
  const [isCrmDeleteDialogOpen, { setTrue: openCrmDeleteDialog, setFalse: closeCrmDeleteDialog }] = useBoolean(false);

  const { data, invalidate } = zodiosHooks.useGetSysteemInstellingen()
  const { mutate: deleteFieldServiceConfiguration } = zodiosHooks.useDeleteSysteemInstellingenFieldService()
  const { mutate: deleteIrisFieldServiceConfiguration } = zodiosHooks.useDeleteSysteemInstellingenIrisFieldService()
  const { mutate: deleteCrmConfiguration } = zodiosHooks.useDeleteSysteemInstellingenCrm()

  const confirmFieldServiceDelete = () => {
    closeFieldServiceDeleteDialog()
    deleteFieldServiceConfiguration(undefined, {
      onSuccess: () => invalidate(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    })
  }

  const confirmIrisFieldServiceDelete = () => {
    closeIrisFieldServiceDeleteDialog()
    deleteIrisFieldServiceConfiguration(undefined,
      {
        onSuccess: () => invalidate(),
        onError: (error) => setError(getTitleAndMessage(error).message)
      })
  }

  const confirmCrmDelete = () => {
    closeCrmDeleteDialog()
    deleteCrmConfiguration(undefined,
      {
        onSuccess: () => invalidate(),
        onError: (error) => setError(getTitleAndMessage(error).message)
      })
  }

  const getExpiryColor = (expiryDate: string | null | undefined) => {
    if (!expiryDate) return ""

    const [day, month, year] = expiryDate.split("-");
    const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    return date < new Date() ? 'red' : ''
  }

  const cardHeight = 200

  return (
    <MainTemplate title="Systeeminstellingen" subTitle="overzicht" commandItems={commandItems} error={error}>
      <DeleteConfirmationDialog onCancel={closeFieldServiceDeleteDialog} hideDialog={!isFieldServiceDeleteDialogOpen} onConfirm={confirmFieldServiceDelete} entityName={'FieldService configuratie'} />
      <DeleteConfirmationDialog onCancel={closeIrisFieldServiceDeleteDialog} hideDialog={!isIrisFieldServiceDeleteDialogOpen} onConfirm={confirmIrisFieldServiceDelete} entityName={'Iris FieldService configuratie'} />
      <DeleteConfirmationDialog onCancel={closeCrmDeleteDialog} hideDialog={!isCrmDeleteDialogOpen} onConfirm={confirmCrmDelete} entityName={'Crm configuratie'} />

      <VheNummeringPanel isOpen={isPanelOpen} dismissPanel={() => dismissPanel()} invalidate={() => invalidate()} />
      <TenantPanel isOpen={isTenantPanelOpen} dismissPanel={() => dismissTenantPanel()} invalidate={() => invalidate()} />
      <VerhuurPanel isOpen={isVerhuurPanelOpen} dismissPanel={() => dismissVerhuurPanel()} invalidate={() => invalidate()} />
      <CrmPanel isOpen={isCrmPanelOpen} dismissPanel={() => dismissCrmPanel()} invalidate={() => invalidate()} />
      <CrmSecretPanel isOpen={isCrmSecretPanelOpen} dismissPanel={() => dismissCrmSecretPanel()} invalidate={() => invalidate()} />

      <FieldServicePanel isOpen={isFieldServicePanelOpen} dismissPanel={() => dismissFieldServicePanel()} invalidate={() => invalidate()} />
      <IrisFieldServicePanel isOpen={isIrisFieldServicePanelOpen} dismissPanel={() => dismissIrisFieldServicePanel()} invalidate={() => invalidate()} />

      <Stack tokens={{ childrenGap: 20 }}>
        <Stack horizontal tokens={{ childrenGap: 10 }} wrap>
          <StackItem grow={1}>
            <InputCard title={'Corporatie-informatie'} maxHeight={cardHeight} active={data?.vheNummerActief} width={'auto'} titleIcons={[
              {
                iconName: 'Edit',
                onClick: () => openTenantPanel()
              }]}>
              <ReadOnlyField title="Id" value={data?.tenantId} />
              <ReadOnlyField title="Naam" value={data?.tenantNaam} />
              <ReadOnlyField title="Entra GUID" value={data?.tenantGuid} />
            </InputCard>
          </StackItem>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 20 }} wrap>
          <StackItem grow={1}>
            <InputCard title={'VHE-nummering'} maxHeight={cardHeight} active={data?.vheNummerActief} width={'auto'} titleIcons={[{ iconName: 'Edit', onClick: () => openPanel() }]}>

              <ReadOnlyField title="Actief" value={data?.vheNummerActief ? 'Ja' : 'Nee'} />
              <ReadOnlyField title="Prefix" value={data?.vheNummerFormat} />
              <ReadOnlyField title="Totale lengte" value={data?.vheNummerTotaleLengteNummer} />
              <ReadOnlyField title="Voorloopkarakter" value={data?.vheNummerVoorloopKarakter} />
              <ReadOnlyField title="Huidig nummer" value={data?.vheNummerHuidigNummer} />
            </InputCard>
          </StackItem>
          <StackItem grow={1}>
            <InputCard title={'Verhuur integratie'} maxHeight={cardHeight} active={!!(data?.verhuurVheUrl || data?.verhuurVgeUrl)} width={'100%'} height={'100%'} titleIcons={[{ iconName: 'Edit', onClick: () => openVerhuurPanel() }]}>
              <ReadOnlyField title="Url naar VHE" value={data?.verhuurVheUrl} />
              <ReadOnlyField title="Url naar VGE" value={data?.verhuurVgeUrl} />
            </InputCard>
          </StackItem>
        </Stack>

        <StackItem grow={1}>
          <Stack horizontal tokens={{ childrenGap: 20 }} wrap>
            <StackItem grow={1}>
              <InputCard title={'Crm integratie'} maxHeight={cardHeight} active={data?.crmActief} width={'auto'} titleIcons={[
                { iconName: 'Edit', onClick: () => openCrmPanel() },
                { iconName: 'Lock', title: 'Wijzigen secret', onClick: () => openCrmSecretPanel() },
                { iconName: data?.crmActief ? 'Delete' : '', onClick: () => openCrmDeleteDialog() }
              ]}>
                <ReadOnlyField title="Url" value={data?.crmEndpoint} />
                <ReadOnlyField title="Scope" value={data?.crmOAuthScope} />
                <ReadOnlyField title="ClientId" value={data?.crmClientId} />
                <ReadOnlyField title="Secret vervalt op" value={data?.crmClientSecretGeldigTot} textColor={getExpiryColor(data?.crmClientSecretGeldigTot)} />
              </InputCard>
            </StackItem>

            <StackItem grow={1}>
              <InputCard title={'FieldService integratie'} maxHeight={cardHeight} active={data?.fieldServiceActief} width={'auto'} titleIcons={[
                { iconName: 'Edit', onClick: () => openFieldServicePanel() },
                { iconName: 'Lock', title: 'Wijzigen secret' },
                { iconName: data?.fieldServiceActief ? 'Delete' : '', onClick: () => openFieldServiceDeleteDialog() }
              ]}>
                <ReadOnlyField title="Url" value={data?.fieldServiceEndpoint} />
                <ReadOnlyField title="Scope" value={data?.fieldServiceOAuthScope} />
                <ReadOnlyField title="ClientId" value={data?.fieldServiceClientId} />
                <ReadOnlyField title="Secret vervalt op" value={data?.fieldServiceClientSecretGeldigTot} textColor={getExpiryColor(data?.fieldServiceClientSecretGeldigTot)} />
              </InputCard>

            </StackItem>

            <StackItem grow={1}>
              <InputCard title={'Iris FieldService integratie'} maxHeight={cardHeight} active={data?.irisFieldServiceActief} width={'auto'} titleIcons={[
                { iconName: 'Edit', onClick: () => openIrisFieldServicePanel() },
                { iconName: 'Lock', title: 'Wijzigen secret' },
                { iconName: data?.irisFieldServiceActief ? 'Delete' : '', onClick: () => openIrisFieldServiceDeleteDialog() }
              ]}>
                <ReadOnlyField title="Url" value={data?.irisFieldServiceEndpoint} />
                <ReadOnlyField title="Scope" value={data?.irisFieldServiceOAuthScope} />
                <ReadOnlyField title="ClientId" value={data?.irisFieldServiceClientId} />
                <ReadOnlyField title="Secret vervalt op" value={data?.irisFieldServiceClientSecretGeldigTot} textColor={getExpiryColor(data?.irisFieldServiceClientSecretGeldigTot)} />
              </InputCard>
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>

    </MainTemplate>
  )
}
