import {createAsyncThunk, createEntityAdapter, createSlice, PayloadAction, EntityId} from '@reduxjs/toolkit'
import {invokeFetch} from 'services/apiClient'
import {RootState} from 'store'
import {PagedEntities} from 'interfaces/pagedEntities'
import {setPendingState, setRejectedState, setSucceededState} from 'lib/sliceSupport'
import * as yup from 'yup'
import {IVge} from 'features/vge/vgeSlice'
import {ITenantTaak} from "../lemdo/tenantTaak/tenantTaakSlice";

export interface IContractGebouw {
    gebouwId: number
    naam: string | null
    code: string | null
}

export interface IOnderhoudsContract {
    id: number
    contractNummer: string
    contractOmschrijving: string
    leverancierId: number
    ingangsDatum: string
    eindDatum: string
    uitersteOpzegDatum: string
    notificatieDatum?: string
    contractBeheerder?: string
    voorVolledigBezit: boolean

    gebouwen: IContractGebouw[]
    vges: IVge[]
    taken: ITenantTaak[]
}

export const contractSchema = yup.object().shape({
    contractNummer: yup.string().required('Het contractnummer is verplicht.').max(20, 'De maximale lengte is 20.'),
    contractOmschrijving: yup.string().required('De contractomschrijving is verplicht.').max(255, 'De maximale lengte is 255.'),
    contractBeheerder: yup.string().optional().max(200, 'De maximale lengte is 200.'),
})

interface IOnderhoudsContractState {
    selectedContract: IOnderhoudsContract | undefined
    selectedId: number | undefined
    status: 'idle' | 'pending' | 'succeeded' | 'failed'
    updateStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
    error: string | null
}

const onderhoudsContractInitialState: IOnderhoudsContractState = {
    selectedContract: undefined,
    selectedId: undefined,
    status: 'idle',
    updateStatus: 'idle',
    error: null,
}

const entityAdapter = createEntityAdapter<IOnderhoudsContract>()

const baseUrl = '/api/OnderhoudsContract'
const basePrefix = 'onderhoudsContract'
const getSliceState = (state: RootState) => state.onderhoudsContracten

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (filter: string | undefined, thunkAPI) => {
    let uri: string
    if (filter) uri = `${baseUrl}?top=50000&filter=${filter}`
    else uri = `${baseUrl}?top=50000`
    return await invokeFetch<PagedEntities<IOnderhoudsContract>>(thunkAPI, 'GET', uri)
})

export const fetchById = createAsyncThunk(`${basePrefix}/fetchById`, async (id: number, thunkAPI) => {
    return await invokeFetch<IOnderhoudsContract>(thunkAPI, 'GET', `${baseUrl}/getById?id=${id}`)
})

export const updateOnderhoudsContract = createAsyncThunk(`${basePrefix}/update`, async (entity: IOnderhoudsContract, thunkAPI) => {
    return await invokeFetch<IOnderhoudsContract>(thunkAPI, 'PUT', baseUrl, entity)
})

export const addOnderhoudsContract = createAsyncThunk(`${basePrefix}/add`, async (entity: IOnderhoudsContract, thunkAPI) => {
    return await invokeFetch<IOnderhoudsContract>(thunkAPI, 'POST', baseUrl, entity)
})

export const deleteOnderhoudsContract = createAsyncThunk(`${basePrefix}/delete`, async (entities: number[], thunkAPI) => {
    return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
})

export const onderhoudsContractSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(onderhoudsContractInitialState),

    reducers: {
        clearError: state => {
            state.error = null
        },
        select: (state, action: PayloadAction<EntityId | undefined>) => {
            state.selectedId = action.payload as number | undefined
        },
        clearSelection: state => {
            state.selectedId = undefined
        },
        add: entityAdapter.addOne,
        modify: entityAdapter.upsertOne,
        removeMany: entityAdapter.removeMany,
        setAll: entityAdapter.setAll,
        setSelectedContract: (state, action: PayloadAction<number>) => {
            state.selectedId = action.payload
            const contract = state.entities[action.payload]
            if (contract) state.selectedContract = contract
        },
    },

    extraReducers: builder => {
        builder.addCase(fetchById.pending, state => setPendingState(state))
        builder.addCase(fetchById.fulfilled, (state, action: PayloadAction<IOnderhoudsContract>) => {
            setSucceededState(state)
            entityAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(fetchById.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(fetchAll.pending, state => setPendingState(state))
        builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IOnderhoudsContract>>) => {
            setSucceededState(state)
            entityAdapter.setAll(state, action.payload.items)
        })
        builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(updateOnderhoudsContract.pending, state => {
            state.error = null
            state.updateStatus = 'pending'
        })
        builder.addCase(updateOnderhoudsContract.fulfilled, (state, action: PayloadAction<IOnderhoudsContract>) => {
            entityAdapter.upsertOne(state, action.payload)
            state.error = null
            state.updateStatus = 'succeeded'
        })
        builder.addCase(updateOnderhoudsContract.rejected, (state, action) => {
            state.updateStatus = 'failed'
            state.error = action.error.message || null
        })

        builder.addCase(addOnderhoudsContract.pending, state => {
            state.error = null
            state.updateStatus = 'pending'
        })
        builder.addCase(addOnderhoudsContract.fulfilled, (state, action: PayloadAction<IOnderhoudsContract>) => {
            entityAdapter.upsertOne(state, action.payload)
            state.error = null
            state.updateStatus = 'succeeded'
        })
        builder.addCase(addOnderhoudsContract.rejected, (state, action) => {
            state.updateStatus = 'failed'
            state.error = action.error.message || null
        })

        builder.addCase(deleteOnderhoudsContract.pending, state => setPendingState(state))
        builder.addCase(deleteOnderhoudsContract.fulfilled, (state, action) => {
            entityAdapter.removeMany(state, action.meta.arg)
            setSucceededState(state)
        })
        builder.addCase(deleteOnderhoudsContract.rejected, (state, action) => setRejectedState(state, action))
    },
})

export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return undefined
}

export const getLoadingState = (state: RootState) => {
    return getSliceState(state).status
}

export const getUpdateState = (state: RootState) => {
    return getSliceState(state).updateStatus
}
export const getErrorState = (state: RootState) => {
    return getSliceState(state).error
}

export const {add, modify, removeMany, select, clearSelection, setSelectedContract} = onderhoudsContractSlice.actions
export const {
    selectAll,
    selectEntities,
    selectById
} = entityAdapter.getSelectors<RootState>(state => state.onderhoudsContracten)

export default onderhoudsContractSlice.reducer
