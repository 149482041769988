import React, { useEffect, useMemo, useState } from 'react'
import {
  CommandBar,
  IColumn,
  ICommandBarItemProps,
  Link
} from '@fluentui/react'
import commandBarStyles from '../../../../styles/commandBarStyles'
import { IVge } from '../../../vge/vgeSlice'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { useBoolean } from '@fluentui/react-hooks'
import { createBooleanColumn, createColumn, createCustomRenderedColumn } from '../../../../lib/gridHelper'
import { createCommandButton } from '../../../../lib/commandBarHelper'
import { PageIdDefinition, PAGES } from '../../../../pages'
import { useNavigate } from 'react-router-dom'
import { useWarningDialog } from '../../../../components/WarningDialog'
import { GebouwDeelVgeListDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { usePagedParams } from '../../../../services/usePagedParams'
import { z } from 'zod'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { CreateUpdateType } from './gebouwdeel'
import SearchVgeForLinkageToGebouwdeel from '../gebouwen/searchVgeForLinkageToGebouwdeel'
import { AdresStatus } from '../gebouwen/gebouwVgeList'


type GebouwDeelVgeListDtoType = z.infer<typeof GebouwDeelVgeListDto>;

interface IGebouwDeelVgeListProps {
  gebouwDeel: CreateUpdateType
  adresStatus: number
  setItemCount: (count: number) => void
}

const GebouwDeelVgeList: React.FC<IGebouwDeelVgeListProps> = props => {
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const navigate = useNavigate()
  const { validatedSearchParams, setOrder, setPage } = usePagedParams()
  const [error, setError] = useState<string>()
  const [WarningDialog, setDialogSubtext, showDialog] = useWarningDialog()
  const [selectedIds, setSelectedIds] = useState([] as number[])


  const { data: vges, invalidate: fetchVges, isLoading } = zodiosHooks.useGetGebouwdelenGebouwDeelIdvges({
    params: { gebouwDeelId: props.gebouwDeel.gebouwDeelId as number },
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter,
      adresStatus: props.adresStatus
    }
  }, { enabled: props.gebouwDeel.gebouwDeelId !== 0 })

  const { mutate: deleteVgesFromGebouwDeel } = zodiosHooks.useDeleteGebouwdelenVge({}, {
    onSuccess: () => fetchVges(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  useEffect(() => {
    if (vges && vges.items) {
      props.setItemCount(vges.items.length)
    }
  }, [vges, props.setItemCount])

  const onSelectionChanged = (ids: number[]) => {
    setSelectedIds(ids)
  }

  const CheckForServiceadres = (vgeId: number, vgesSelected: number[]) => {
    if (props.gebouwDeel?.serviceadresVge && vgesSelected.find((v: number) => v === props.gebouwDeel?.serviceadresVge)) {
      setDialogSubtext('Vastgoedeenheid kan niet worden ontkoppeld aangezien deze wordt gebruikt als serviceadres')
      showDialog(true)
    } else {
      deleteVgesFromGebouwDeel({ gebouwdeelId: props.gebouwDeel.gebouwDeelId as number, vgeIds: [vgeId] })
    }
  }

  const AddToSelection = () => {
    openPanel()
  }

  const RemoveFromSelection = () => {
    if (selectedIds.length > 0) {
      selectedIds.forEach(vgeId => {
        CheckForServiceadres(vgeId, selectedIds)
      })
    }
  }

  const selectCommandBarItems: ICommandBarItemProps[] = useMemo(
    () => [
      createCommandButton('Koppelen', 'PlugConnected', AddToSelection),
      createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveFromSelection)],
    [AddToSelection, RemoveFromSelection, selectedIds]
  )

  const handleItemInvoked = ({ vgeId }: GebouwDeelVgeListDtoType) => {
    navigate(PAGES[PageIdDefinition.vge.edit].route.replace(':id', vgeId.toString()))
  }

  const columns: IColumn[] = [
    createColumn('Eenheiddetailsoort', getPropertyName<GebouwDeelVgeListDtoType>('srtNm'), 'XL'),
    createColumn('Woonplaats', getPropertyName<GebouwDeelVgeListDtoType>('wpl')),
    createCustomRenderedColumn(
      'Straat',
      getPropertyName<GebouwDeelVgeListDtoType>('str'),
      (item: GebouwDeelVgeListDtoType) => (
        <Link key={item.str} onClick={() => handleItemInvoked(item)}>
          {item.str}
        </Link>
      ),
      'XL',
      false
    )
  ]
  if (props.adresStatus === AdresStatus.MetAdres) {
    columns.push(
      createColumn('Postcode', getPropertyName<GebouwDeelVgeListDtoType>('pc')),
      createColumn('Huisnr.', getPropertyName<GebouwDeelVgeListDtoType>('nr')),
      createColumn('Huislet.', getPropertyName<GebouwDeelVgeListDtoType>('ltr')),
      createColumn('Toev.', getPropertyName<GebouwDeelVgeListDtoType>('nrT')),
      createColumn('Locatieaanduiding', getPropertyName<IVge>('locatieaanduiding'), 'L'),
      createColumn('Gebouwdeel', getPropertyName<GebouwDeelVgeListDtoType>('gdlCd')),
      createCustomRenderedColumn('Verhuureenheid', getPropertyName<GebouwDeelVgeListDtoType>('vhes'), item => item[getPropertyName<IVge>('vhes')].join(', '), 125, false),
      createColumn('Beheerder', getPropertyName<GebouwDeelVgeListDtoType>('bn'), 'XL')
    )
  } else if (props.adresStatus === AdresStatus.ZonderAdres) {
    columns.push(
      createColumn('Adresaanduiding', getPropertyName<GebouwDeelVgeListDtoType>('aa'), 115),
      createColumn('Locatieaanduiding', getPropertyName<GebouwDeelVgeListDtoType>('locatieaanduiding'), 'L'),
      createColumn('Gebouwdeel', getPropertyName<GebouwDeelVgeListDtoType>('gdlCd'), 90),
      createCustomRenderedColumn('Verhuureenheid', getPropertyName<GebouwDeelVgeListDtoType>('vhes'), item => item[getPropertyName<IVge>('vhes')].join(', '), 110, false),
      createColumn('Gerelateerd', getPropertyName<GebouwDeelVgeListDtoType>('rel'), 'L'),
      createColumn('Beheerder', getPropertyName<GebouwDeelVgeListDtoType>('bn'), 175),
      createBooleanColumn('Historisch', getPropertyName<GebouwDeelVgeListDtoType>('hist'), 'S')
    )
  } else if (props.adresStatus === AdresStatus.MetTerrein) {
    columns.push(
      createColumn('Locatieaanduiding', getPropertyName<GebouwDeelVgeListDtoType>('locatieaanduiding'), 'L'),
      createColumn('Terreinclassificatie', getPropertyName<GebouwDeelVgeListDtoType>('trnNm'), 240, false),
      createColumn('Kadastrale aand. vlak', getPropertyName<GebouwDeelVgeListDtoType>('kadV'), 'L', false),
      createColumn('Grootte', getPropertyName<GebouwDeelVgeListDtoType>('grt'), 75, false),
      createColumn('Opmerking', getPropertyName<GebouwDeelVgeListDtoType>('opm'), 'L', false),
      createBooleanColumn('Historisch', getPropertyName<GebouwDeelVgeListDtoType>('hist'), 'S')
    )
  }


  return (
    <>
      <CommandBar items={selectCommandBarItems} styles={commandBarStyles} />
      {WarningDialog}
      <ErrorMessageBar error={error} />
      <SearchVgeForLinkageToGebouwdeel
        dismissPanel={dismissPanel}
        isOpen={isPanelOpen}
        gebouwDeelId={props.gebouwDeel.gebouwDeelId}
        adresStatus={props.adresStatus}
        invalidate={fetchVges}
      />
      <div style={{ height: '70vh', position: 'relative' }}>
        <VgtPagedTable
          isLoading={isLoading}
          items={vges?.items ?? []}
          columns={columns}
          height={'203'}
          pagingInfo={{
            pageSize: validatedSearchParams.pageSize,
            currentPage: validatedSearchParams.page,
            totalCount: vges?.totalCount ?? 0,
            totalPages: vges?.totalPages ?? 0,
            hasNextPage: vges?.hasNextPage ?? false,
            hasPreviousPage: vges?.hasPreviousPage ?? false,
            sortKey: validatedSearchParams.sortKey || 'vgeId',
            sortDirection: validatedSearchParams.sortDirection || 'desc'
          }}
          onSelectionChanged={onSelectionChanged}
          getKey={(item) => item?.vgeId}
          onItemInvoked={(item) => handleItemInvoked(item)}
          onPageChanged={setPage}
          onSortChanged={setOrder}
        />
      </div>
    </>
  )
}

export default GebouwDeelVgeList
