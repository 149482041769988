import React, { useEffect, useMemo, useState } from 'react'
import {
  DefaultButton,
  ISpinnerStyles,
  IStackStyles,
  mergeStyleSets,
  Spinner,
  SpinnerSize,
  Stack,
  Text
} from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import EditPanelElementElementDetail from './editPanel'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'
import { ElementElementDetailListDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { z } from 'zod'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { usePagedParams } from '../../../../services/usePagedParams'
import { VgtSearch } from '../../../../components/VgeSearch/VgtSearch'
import ElementElementDetailRow from './elementElementDetailRow'

const classNames = mergeStyleSets({
  containerStyles: {
    height: '420px',
    width: '100%',
    overflow: 'auto',
    marginBottom: 5
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center'
  },
  columnHeader: {
    width: 150,
    maxWidth: 200,
    fontWeight: '700',
    paddingLeft: 2,
    cursor: 'pointer'
  }
})

const stackStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '5px',
    overflow: 'auto',
    minWidth: '1500px'
  }
}

const spinnerStyles: Partial<ISpinnerStyles> = {
  root: {
    zIndex: 100,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}

type ElementElementDetailListDtoType = z.infer<typeof ElementElementDetailListDto>;

type sortOrder = {
  id: 'asc' | 'desc';
  element: 'asc' | 'desc';
  elementDetail: 'asc' | 'desc';
  materiaalSoortNaam: 'asc' | 'desc';
  nlSfBCode: 'asc' | 'desc';
  nlSfBOmschrijving: 'asc' | 'desc';
};

const ElementElementDetailList: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: closePanel }] = useBoolean(false)
  const { isAdmin } = useRoles()
  const [selectedElementElementDetailspage, setSelectedElementElementDetailspage] = useState<ElementElementDetailListDtoType | undefined>()
  const [error, setError] = useState<string>()
  const { validatedSearchParams, setFilter, setPage, setOrder } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [sortOrder, setSortOrder] = useState<sortOrder>({
    id: 'asc',
    element: 'asc',
    elementDetail: 'asc',
    materiaalSoortNaam: 'asc',
    nlSfBCode: 'asc',
    nlSfBOmschrijving: 'asc'
  })

  const {
    data: elementElementDetailspage,
    invalidate: fetchElementElementDetailspageItems,
    isLoading: isFetchingElementElementDetails
  } = zodiosHooks.useGetElementElementDetailspage({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { mutate: deleteElementElementDetails } = zodiosHooks.useDeleteElementElementDetails({}, {
    onSuccess: () => fetchElementElementDetailspageItems(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const toggleOrder = (column: keyof sortOrder) => {
    setSortOrder(prevOrder => ({
      ...prevOrder,
      [column]: prevOrder[column] === 'asc' ? 'desc' : 'asc'
    }))
    setOrder(column, sortOrder[column] === 'asc' ? 'desc' : 'asc')
  }

  useEffect(() => {
    setOrder('id', 'asc')
  }, [])

  const refreshItems = () => fetchElementElementDetailspageItems()

  const handleListSelectionChanged = (id: number) => {
    setSelectedIds((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        return prevSelectedItems.filter(item => item !== id)
      } else {
        return [...prevSelectedItems, id]
      }
    })
  }

  const removeItems = () => {
    deleteElementElementDetails(selectedIds)
  }

  const commandItems = useMemo(
    () => [
      { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
      { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
      {
        visible: isAdmin,
        text: 'Toevoegen',
        onClick: () => {
          setSelectedElementElementDetailspage(undefined)
          openPanel()
        },
        icon: 'Add'
      },
      {
        visible: isAdmin,
        text: 'Verwijderen',
        onClick: () => removeItems(),
        icon: 'Delete',
        disabled: !selectedIds.length
      }
    ],
    [isAdmin, selectedIds.length]
  )

  return (
    <MainTemplate title='Cartotheek-item' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <VgtSearch onSearch={setFilter} filter={validatedSearchParams.filter} />
      <EditPanelElementElementDetail fetchElementElementDetailspageItems={fetchElementElementDetailspageItems}
                                     selectedElementElementDetailspage={selectedElementElementDetailspage}
                                     dismissPanel={closePanel} isOpen={isPanelOpen} />
      <div className={classNames.containerStyles}>
        <Stack styles={stackStyles}>
          <div className={classNames.headerRow}>
            <span style={{ width: 100 }}></span>
            <Text style={{ width: 100, maxWidth: 150, fontWeight: '700', paddingLeft: 2 }}
                  onClick={() => toggleOrder('id')}>Nr.</Text>
            <Text className={classNames.columnHeader} onClick={() => toggleOrder('element')}>Element</Text>
            <Text className={classNames.columnHeader} onClick={() => toggleOrder('elementDetail')}>Elementdetail</Text>
            <Text className={classNames.columnHeader}
                  onClick={() => toggleOrder('materiaalSoortNaam')}>Materiaalsoort</Text>
            <Text className={classNames.columnHeader} onClick={() => toggleOrder('nlSfBCode')}>NL-SfB Elementcode</Text>
            <Text className={classNames.columnHeader} onClick={() => toggleOrder('nlSfBOmschrijving')}>NL-SfB
              Omschrijving</Text>
          </div>
          {isFetchingElementElementDetails ? <Spinner size={SpinnerSize.large}
                                                      styles={spinnerStyles} /> : elementElementDetailspage && elementElementDetailspage?.items?.map((item, index) =>
            <ElementElementDetailRow key={item.id} index={index} item={item}
                                     setSelectedElementElementDetailspage={setSelectedElementElementDetailspage}
                                     openEditPanel={openPanel}
                                     handleItemSelectionChanged={handleListSelectionChanged}
                                     fetchElementElementDetailspageItems={fetchElementElementDetailspageItems} />
          )}
        </Stack>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', padding: 12 }}>
        <div>
          <DefaultButton iconProps={{ iconName: 'previous' }} disabled={!elementElementDetailspage?.hasPreviousPage}
                         onClick={() => setPage(validatedSearchParams.page - 1)} />
        </div>
        <div style={{ paddingLeft: 12, paddingTop: 4 }}>
          <Text>{elementElementDetailspage?.totalCount} resultaten.
            Pagina {(validatedSearchParams.page).toString()} van {elementElementDetailspage?.totalPages}</Text>
        </div>
        <div style={{ paddingLeft: 12 }}>
          <DefaultButton iconProps={{ iconName: 'next' }} disabled={!elementElementDetailspage?.hasNextPage}
                         onClick={() => setPage(validatedSearchParams.page + 1)} />
        </div>
      </div>
    </MainTemplate>
  )
}

export default ElementElementDetailList
