import React, { useCallback, useState } from 'react'
import {
  Checkbox,
  DetailsList,
  IButtonStyles,
  ICheckboxStyles,
  IconButton,
  IDetailsHeaderStyles,
  IDetailsListProps,
  IStackStyles,
  Link,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Text
} from '@fluentui/react'
import { z } from 'zod'
import { GebouwenListDto, GerelateerdGebouwDeelListDto } from '../../../../api/ApiClient'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { useNavigate } from 'react-router-dom'
import { createCustomRenderedColumn } from '../../../../lib/gridHelper'
import { DetailsHeader } from '@fluentui/react/lib/DetailsList'
import { availableUrls } from '../../../../routing/AvailableUrls'

type GebouwenListDtoType = z.infer<typeof GebouwenListDto>;
type GerelateerdGebouwDeelListDtoType = z.infer<typeof GerelateerdGebouwDeelListDto>;

interface IGebouwenRowProps {
  item: GebouwenListDtoType;
  index: number;
  handleItemSelectionChanged: (id: number) => void;
}

const checkBoxStyles: Partial<ICheckboxStyles> = {
  checkbox: {
    borderRadius: '50%'
  }
}

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    width: 60,
    height: 'auto'
  }
}

const GebouwenRow: React.FC<IGebouwenRowProps> = ({
                                                    item,
                                                    handleItemSelectionChanged,
                                                    index
                                                  }) => {
  const navigate = useNavigate()
  const [showGebouwenTable, setShowGebouwenTable] = useState<boolean>(false)
  const [itemChecked, setItemChecked] = useState<boolean>(false)

  const gebouwenRowStackStyles: IStackStyles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '20px',
      backgroundColor: index % 2 === 0 ? '#f7f7f7' : '#ffffff',
      '&:hover': {
        backgroundColor: '#f3f3f3'
      }
    }
  }

  const itemStackStyles: IStackStyles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '20px'
    }
  }

  const onGebouwdeelClick = (id: string) => {
    navigate(availableUrls.Gebouwdeel.replace(':id', id))
  }

  const handleItemInvoked = (id: string) => {
    navigate(availableUrls.GebouwEdit.replace(':id', id))
  }

  const columns = [
    createCustomRenderedColumn(
      'Gebouwdeelcode',
      getPropertyName<GerelateerdGebouwDeelListDtoType>('code'),
      (item: GerelateerdGebouwDeelListDtoType) => (
        <Link key={item.gebouwDeelId} onClick={() => onGebouwdeelClick(item.gebouwDeelId.toString())}>
          {item.code}
        </Link>
      ),
      'L',
      false
    ),
    createCustomRenderedColumn(
      'Gebouwdeelnaam',
      getPropertyName<GerelateerdGebouwDeelListDtoType>('naam'),
      (item: GerelateerdGebouwDeelListDtoType) => <span style={{ fontSize: 14, fontWeight: 600 }}>{item.naam}</span>
    )
  ]

  const onItemSelection = (id: number) => {
    setItemChecked(!itemChecked)
    handleItemSelectionChanged(id)
  }

  const onCheckboxChange = useCallback(
    (): void => {
      setItemChecked(!itemChecked)
      handleItemSelectionChanged(item?.gebouwId)
    },
    [itemChecked]
  )

  const _onRenderDetailsHeader: IDetailsListProps['onRenderDetailsHeader'] = props => {
    const headerStyles: Partial<IDetailsHeaderStyles> = {
      root: {
        margin: 0,
        padding: 0
      }
    }
    if (props) {
      return <DetailsHeader {...props} ariaLabelForToggleAllGroupsButton={'Toggle selection'} styles={headerStyles} />
    }
    return null
  }

  return (
    <>
      <Stack styles={gebouwenRowStackStyles}>
        {item?.gebouwDelen?.length! > 0 ?
          <IconButton iconProps={{ iconName: showGebouwenTable ? 'Remove' : 'Add' }}
                      onClick={() => setShowGebouwenTable(!showGebouwenTable)}
                      styles={iconButtonStyles}
          /> :
          <span style={{ width: 60 }}></span>}
        <Checkbox checked={itemChecked} onChange={onCheckboxChange} styles={checkBoxStyles} />
        <Stack onClick={() => onItemSelection(item.gebouwId)} styles={itemStackStyles}>
          <Link key={item.gebouwId} onClick={() => handleItemInvoked(item.gebouwId.toString())}
                style={{ width: 150 }}>{item.code}</Link>
          <Text style={{ width: 'auto' }}>{item.naam}</Text>
        </Stack>
      </Stack>
      {showGebouwenTable && (
        item?.gebouwDelen ?
          (item?.gebouwDelen?.length > 0 &&
            <div style={{ marginLeft: 110 }}>
              <DetailsList
                compact={true}
                columns={columns}
                styles={{ root: { fontSize: '14px' } }}
                items={item?.gebouwDelen}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={_onRenderDetailsHeader}
              />
            </div>
          )
          : <Spinner size={SpinnerSize.large} />)}
    </>
  )
}

export default GebouwenRow
