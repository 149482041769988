import {Panel, PanelType, Text} from '@fluentui/react'
import {FieldErrorsToMessage} from '../../components/ErrorMessageBar/ErrorMessageBar'
import React, {useEffect, useState} from 'react'
import {OkCancelButtonStack} from '../../components/OkCancelButtonStack/OkCancelButtonStack'
import VgtDropdown from '../../components/FluentDropdown'
import {getPropertyName} from '../../lib/interfaceUtils'
import {Controller, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {VgeSetHistorischDto, zodiosHooks} from '../../api/ApiClient'
import {z} from 'zod'
import {getTitleAndMessage} from '../../services/HandleError'
import useRoles from '../../services/UseRoles'
import VgtHorizontalDatePicker from '../../components/VgtDatePicker/VgtHorizontalDatePicker'
import {availableUrls} from '../../routing/AvailableUrls'
import {formatIsoDate} from '../../components/VgtDatePicker'
import {HistorischRedenVerkoop} from "../../lib/constants/enumConstants";
import {ExplainCard} from "../../components/ExplainCard/ExplainCard";
import FluentPanel from "../../components/FluentPanel/FluentPanel";

export interface IHistorischPanelProps {
  isOpen: boolean
  dismissPanel: () => void
  onModified: () => void
  vgeIds: number[]
}

type CreateUpdateType = z.infer<typeof VgeSetHistorischDto>;
const defaultCreateUpdateType = {
  redenId: 0,
  vgeIds: [-1],
  verkoopAanId: 0,
  vanaf: new Date().toISOString()
}

function PanelFooterLine() {
  return <div style={{marginTop: 16, borderBottom: '1px solid rgb(207, 207, 207)'}}/>
}

export const VgeHistorischPanel = (props: IHistorischPanelProps) => {
  const {isVastgoedbeheer} = useRoles()
  const [error, setError] = useState<string>()
  const isMultiEdit = props.vgeIds.length > 1
  const vgeIdsIsValid = props.vgeIds.length > 0 && 
    props.vgeIds.every(vgeId => Number.isFinite(vgeId) && vgeId > 0)

  const {data: redenOpties} = zodiosHooks.useGetHistorischOpties({}, {enabled: vgeIdsIsValid && props.isOpen})
  const {data: verkoopAanOpties} = zodiosHooks.useGetVerkoopAanOpties({}, {enabled: vgeIdsIsValid && props.isOpen})
  const {data} = zodiosHooks.useGetHistorisch(
    {queries: {VgeIds: props.vgeIds.join(',')}}, {
      enabled: vgeIdsIsValid && props.isOpen,
      onSuccess: () => props.onModified,
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )
  
  const {
    control,
    handleSubmit,
    watch,
    formState: {errors}
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(VgeSetHistorischDto), mode: 'all', defaultValues: defaultCreateUpdateType
  })

  const {mutate, isLoading: isSubmitting} = zodiosHooks.usePostHistorisch(
    {}, {
      onSuccess: props.onModified,
      onError: (error) => setError(getTitleAndMessage(error).message)
    })

  useEffect(() => {
    if (!props.isOpen) return
    setError(undefined)
  }, [props.isOpen])

  const onSubmit = (values: CreateUpdateType) => {    
    values.vgeIds = props.vgeIds
    console.log(`values: ${values.vanaf}`);
    mutate(values)
  }

  const redenId = watch(getPropertyName<CreateUpdateType>('redenId'))

  if (data === undefined) {
    return (<></>)
  }  

  if (data?.some(d => d.historisch)) {
    return (
      <Panel type={PanelType.medium} customWidth="61%" headerText={'Historisch maken'} isOpen={props.isOpen}
             onDismiss={props.dismissPanel} closeButtonAriaLabel="Close">
        <ExplainCard type={'Error'}  headerText={'Historisch maken niet mogelijk'}>
          <Text variant="mediumPlus">Een of meerdere vastgoedeenheden uit de selectie zijn reeds historisch.</Text>
        </ExplainCard>
      </Panel>
    )
  }

  if (data?.some(d => d.verhuureenhedenNietUitExploitatie?.length ?? -1 > 0)) {
    return (
      <Panel type={PanelType.custom} customWidth="61%" headerText={'Historisch maken'} isOpen={props.isOpen}
             onDismiss={props.dismissPanel} closeButtonAriaLabel="Close">
        <ExplainCard type={'Warning'} headerText={'Historisch maken niet mogelijk'}>
          <Text variant="mediumPlus">De volgende vhe's bij deze
            vastgoedeenheid zijn nog in exploitatie:</Text>
          <ul>
            {data?.map(vge => vge.verhuureenhedenNietUitExploitatie?.map(vhe =>
              <li key={vhe.id}><a
                href={availableUrls.Vhes_Edit.replace(':id', vhe?.id?.toString())}>{vhe.code} - {vhe.adres}</a>
              </li>))}
          </ul>
        </ExplainCard>
      </Panel>
    )
  }

  return (
    <FluentPanel type={PanelType.medium} customWidth={'60%'} headerText={'Historisch maken'} isOpen={props.isOpen}
                 onDismiss={props.dismissPanel} closeButtonAriaLabel="Close" error={error}>
      <Controller
        name={getPropertyName<CreateUpdateType>('vgeIds')}
        control={control}
        defaultValue={"-1"}
        render={() => <input type="hidden"/>}
      />
      <ExplainCard type={'Info'} headerText={'Het volgende gaat u hiermee wijzigen'}>
        <table cellPadding={3} style={{paddingLeft: '2px', marginTop: "6px"}}>
          <tbody>
          <tr>
            <td width={'250px'}>Historisch</td>
            <td>'Nee'</td>
            <td>wordt</td>
            <td>'Ja'</td>
          </tr>
          <tr>
            <td>Verhuurtype</td>
            {isMultiEdit && <td></td>}
            {!isMultiEdit && data &&
                <td style={{whiteSpace: 'nowrap'}}>'{data[0]?.verhuurtypeOptie}'</td>}
            <td>wordt</td>
            <td style={{whiteSpace: 'nowrap'}}>'Niet verhuurbaar'</td>
          </tr>
          <tr>
            <td>Reparatieverzoek toegestaan</td>
            {isMultiEdit && <td></td>}
            {!isMultiEdit && data && <td>'{data[0]?.reparatieverzoekToegestaan ? 'Ja' : 'Nee'}'</td>}
            <td>wordt</td>
            <td>'Nee'</td>
          </tr>
          {isMultiEdit &&
              <tr>
                  <td>Alle gerelateerde vastgoeditems zullen dezelfde waarden krijgen</td>
              </tr>
          }
          </tbody>
        </table>
      </ExplainCard>

      <VgtDropdown
        options={redenOpties}
        label="Reden historisch"
        name={getPropertyName<CreateUpdateType>('redenId')}
        required={true}
        control={control}
        errors={errors}
      />
      <VgtDropdown
        showIf={redenId === HistorischRedenVerkoop}
        options={verkoopAanOpties}
        label="Verkoop aan"
        name={getPropertyName<CreateUpdateType>('verkoopAanId')}
        required={true}
        control={control}
        errors={errors}
      />
      <VgtHorizontalDatePicker
        name={getPropertyName<CreateUpdateType>('vanaf')}
        label={'Historisch vanaf'}
        defaultValue={formatIsoDate(new Date())}
        control={control}
        required={true}
        errors={errors}
      />

      <PanelFooterLine/>

      <OkCancelButtonStack isSubmitting={isSubmitting}
                           isLoading={false}
                           defaultButtonLabel={'Uitvoeren'}
                           isWriter={isVastgoedbeheer}
                           onOkClick={handleSubmit(onSubmit, (errors) => {
                             console.log(errors)
                             setError(FieldErrorsToMessage(errors))
                           })}
                           onCancelClick={() => props.dismissPanel()}/>
    </FluentPanel>    
  )
}