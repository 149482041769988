import { DefaultButton, IDropdownOption, Panel, PanelType, Stack } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
  addTenantRuimteSoort,
  deleteTenantRuimteSoort,
  getLoadingState,
  getSelectedEntity,
  ITenantRuimteSoort,
  TenantRuimteSoortSchema,
  updateTenantRuimteSoort
} from './tenantRuimteSoortenSlice'
import { selectAll as selectAllDefectLocaties } from '../defectLocatie/defectLocatieSlice'
import { selectAll as selectAllRuimteSoortenErp } from '../ruimtesoortErp/ruimtesoortErpSlice'
import { useEffect, useState } from 'react'
import useRoles from 'services/UseRoles'
import VgtDropdown from 'components/FluentDropdown'

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
}

export const TenantRuimteSoortEditPanel: React.FC<IEditPanelProps> = props => {
  const dispatch = useAppDispatch()
  const [title, setTitle] = useState('')
  const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
  const fetchStatus = useSelector(getLoadingState)
  const selectedEntity = useSelector(getSelectedEntity)
  const { isAdmin } = useRoles()

  const ruimteSoortenERP = useSelector(selectAllRuimteSoortenErp)
  const defectLocaties = useSelector(selectAllDefectLocaties)

  const displayDefectLocatieOption = (defectLocatieId?: number) => {
    const defectLocatie = defectLocaties.filter(x => x.id === defectLocatieId)
    return defectLocatie.map(x => ({ key: x.id, text: `${x.naam}` })) as IDropdownOption[]
  }

  const getRuimteSoortenAsOptions = () => {
    const nullOption = { key: 0, text: '---' } as IDropdownOption
    const ruimteSoortErpOptions = ruimteSoortenERP.map(x => ({
      key: x.ruimtesoortErpId,
      text: `${x.code} - ${x.omschrijving}`
    })) as IDropdownOption[]
    return [nullOption, ...ruimteSoortErpOptions]
  }

  useEffect(() => {
    setCloseOnFulFilled(false)
  }, [])

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<ITenantRuimteSoort>({ resolver: yupResolver(TenantRuimteSoortSchema), mode: 'all' })

  useEffect(() => {
    setTitle(`Defectlocatie || Ruimtesoort wijzigen`)
    if (selectedEntity) {
      reset(selectedEntity)
    } else {
      reset({})
    }
  }, [selectedEntity])

  const onCancel = e => {
    props.dismissPanel()
  }

  const onSubmit = (data: ITenantRuimteSoort) => {
    if (selectedEntity) {
      if (data.ruimtesoortErpId !== 0 && selectedEntity.ruimtesoortErpId === undefined) {
        dispatch(addTenantRuimteSoort(data))
        setCloseOnFulFilled(true)

      } else if (data.ruimtesoortErpId === 0) {
        dispatch(deleteTenantRuimteSoort([selectedEntity.defectLocatieId]))
        setCloseOnFulFilled(true)
      } else {
        data.defectLocatieId = selectedEntity.defectLocatieId
        dispatch(updateTenantRuimteSoort(data))
        setCloseOnFulFilled(true)
      }
    }
  }


  useEffect(() => {
    if (closeOnFulFilled && fetchStatus === 'succeeded') props.dismissPanel()
  }, [fetchStatus])

  return (
    <div>
      <Panel type={PanelType.large} headerText={title} isOpen={props.isOpen} onDismiss={onCancel}
             closeButtonAriaLabel='Sluiten'>
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtDropdown
            options={displayDefectLocatieOption(selectedEntity?.defectLocatieId)}
            label='Defectlocatie'
            name={getPropertyName<ITenantRuimteSoort>('defectLocatieId')}
            register={register}
            control={control}
            errors={errors}
            readOnly={true}
          />
          <VgtDropdown
            options={getRuimteSoortenAsOptions()}
            label='Ruimtesoort ERP'
            name={getPropertyName<ITenantRuimteSoort>('ruimtesoortErpId')}
            register={register}
            control={control}
            errors={errors}
            readOnly={!isAdmin}
          />
          {isAdmin ?
            <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
              <DefaultButton text='Opslaan' type='submit' primary disabled={isSubmitting}
                             onClick={handleSubmit(onSubmit)} />
              <DefaultButton text='Annuleren' onClick={onCancel} />
            </Stack> : null}
        </form>
      </Panel>
    </div>
  )
}
export default TenantRuimteSoortEditPanel