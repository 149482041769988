import React from 'react'
import { IDropdownOption, Panel, PanelType } from '@fluentui/react'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import useRoles from '../../../../services/UseRoles'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  VgeOppervlakteNen2580AddOrUpdateDto,
  zodiosHooks
} from '../../../../api/ApiClient'
import { z } from 'zod'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'
import VgtDropdown from '../../../../components/FluentDropdown'
import VgtCheckbox from '../../../../components/FluentCheckbox'
import InputCard from '../../../../components/InputCard'
import DecimailInputField from '../../../../components/DecimailInputField'
import { getTitleAndMessage } from '../../../../services/HandleError'
import VgtTextField from '../../../../components/VgtTextField'

export interface VgeOppervlaktePanelProps {
  vgeId: number;
  bouwlaagId: number;
  isOpen: boolean;
  dismissPanel: () => void;
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof VgeOppervlakteNen2580AddOrUpdateDto>;

export const VgeOppervlaktePanel: React.FC<VgeOppervlaktePanelProps> = props => {

  const [error, setError] = React.useState<string>()
  const { isVastgoedbeheer } = useRoles()

  const defaultVgeOppervlakteNen2580 = {
    vgeId: props.vgeId,
    bouwlaagId: -1,
    kompasrichtingOptie: null,
    goWoonfunctie: null,
    goOverigeFunctie: null,
    goGemeenschappelijkeRuimten: null,
    goUtiliteitsfuncties: null,
    goOverigeAangrenzendeRuimteBergingGarage: null,
    goGetekendVolgensNen: false,
    bvoGebouw: null,
    bvoOverdekteGebouwgebondenBuitenruimten: null,
    bvoNietOverdekteGebouwgebondenBuitenruimten: null,
    bvoGemeenschappelijkeBinnenruimteWoningen: null,
    bvoGemeenschappelijkeBinnenruimteBouwlaag: null,
    bvoGemeenschappelijkeBinnenruimteGebouw: null,
    bvoGemeenschappelijkeNietOverdekteBuitenruimten: null,
    bvoOverigeRuimten: null,
    vvoUnit: null,
    vvoGemeenschappelijkeRuimten: null,
    vvoOverigeRuimten: null
  } as CreateUpdateType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(VgeOppervlakteNen2580AddOrUpdateDto), mode: 'all', defaultValues: defaultVgeOppervlakteNen2580
  })

  const { mutate: addVgeOppervlakteNen2580 } = zodiosHooks.usePostVgeOppervlakteNen2580({}, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  zodiosHooks.useGetVgeOppervlakteNen2580vgesVgeIdbouwlagenBouwlaagId({
    params: {
      vgeId: props.vgeId,
      bouwlaagId: props.bouwlaagId
    }
  }, {
    onSuccess: (data) => reset(data),
    enabled: props.bouwlaagId > 0
  })

  const { mutate: updateVgeOppervlakteNen2580 } = zodiosHooks.usePutVgeOppervlakteNen2580vgesVgeIdbouwlagenBouwlaagId({ params: { vgeId: props.vgeId, bouwlaagId: props.bouwlaagId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const onCancel = () => {
    props.invalidate()
    reset({})
    setError(undefined)
    props.dismissPanel()
  }


  const onSubmit = (data: CreateUpdateType) => {
    setError(undefined)
    if (props.bouwlaagId !== 0) {
      updateVgeOppervlakteNen2580(data)
    } else {
      addVgeOppervlakteNen2580(data)
    }
  }

  const { data: bouwlagen } = zodiosHooks.useGetApiBouwlagen()
  const { data: kompasrichting } = zodiosHooks.useGetVgeOppervlakteNen2580kompasrichtingOpties()

  const bouwlagenOpties = bouwlagen?.items?.map((item) => {
    return { key: item.id, text: item.naam }
  })

  const kompasrichtingOpties = kompasrichting?.map((item) => {
    return { key: item.key, text: item.text }
  })

  return (
    <Panel type={PanelType.custom} customWidth="80%"
           headerText={'NEN2580_Oppervlakte'} isOpen={props.isOpen}
           onDismiss={props.dismissPanel} closeButtonAriaLabel="Close">
      <br />
      <ErrorMessageBar error={error} />
      <form onSubmit={handleSubmit(onSubmit, (errors) => {
        console.log(errors)
      })}>
        <VgtTextField
          label="Vge"
          name={getPropertyName<CreateUpdateType>('vgeId')}
          register={register}
          control={control}
          errors={errors}
          showIf={false}
          defaultValue={props.vgeId.toString()}
        />
        <VgtDropdown
          label="Bouwlaag"
          register={register}
          name={getPropertyName<CreateUpdateType>('bouwlaagId')}
          control={control} options={bouwlagenOpties as IDropdownOption[]} errors={errors}
          required={true} readOnly={!isVastgoedbeheer}
        />
        <VgtDropdown
          label="Kompasrichting"
          register={register}
          name={getPropertyName<CreateUpdateType>('kompasrichtingOptie')}
          control={control} options={kompasrichtingOpties as IDropdownOption[]} errors={errors}
          required={true} readOnly={!isVastgoedbeheer}
        />

        <InputCard title="Gebruiksoppervlakte" boldTitle marginTop={'12px'} width="100%">
          <DecimailInputField label="Woonfunctie" name={getPropertyName<CreateUpdateType>('goWoonfunctie')}
                              control={control}
                              register={register}
                              errors={errors} labelWidth={400} />
          <DecimailInputField label="Overige functie" name={getPropertyName<CreateUpdateType>('goOverigeFunctie')}
                              register={register}
                              control={control}
                              errors={errors} labelWidth={400} />
          <DecimailInputField label="Gemeenschappelijke ruimten"
                              name={getPropertyName<CreateUpdateType>('goGemeenschappelijkeRuimten')}
                              register={register}
                              control={control}
                              errors={errors} labelWidth={400} />
          <DecimailInputField label="Utiliteitsfuncties"
                              name={getPropertyName<CreateUpdateType>('goUtiliteitsfuncties')}
                              register={register}
                              control={control}
                              errors={errors} labelWidth={400} />
          <DecimailInputField label="Overige aangrenzende ruimte (berging/garage)"
                              register={register}
                              name={getPropertyName<CreateUpdateType>('goOverigeAangrenzendeRuimteBergingGarage')}
                              control={control}
                              errors={errors} labelWidth={400} />
          <VgtCheckbox label="GetekendVolgensNen" name={getPropertyName<CreateUpdateType>('goGetekendVolgensNen')}
                       register={register}
                       control={control} />
        </InputCard>

        <InputCard title="Bruto vloeroppervlakte" boldTitle marginTop={'12px'} width="100%">
          <DecimailInputField label="Gebouw" name={getPropertyName<CreateUpdateType>('bvoGebouw')} control={control}
                              register={register}
                              errors={errors} labelWidth={400} />
          <DecimailInputField label="Overdekte gebouwgebonden buitenruimten"
                              register={register}
                              name={getPropertyName<CreateUpdateType>('bvoOverdekteGebouwgebondenBuitenruimten')}
                              control={control}
                              errors={errors} labelWidth={400} />
          <DecimailInputField label="Niet overdekte gebouwgebonden buitenruimten"
                              register={register}
                              name={getPropertyName<CreateUpdateType>('bvoNietOverdekteGebouwgebondenBuitenruimten')}
                              control={control}
                              errors={errors} labelWidth={400} />
          <DecimailInputField label="Gemeenschappelijke binnenruimten woningen"
                              register={register}
                              name={getPropertyName<CreateUpdateType>('bvoGemeenschappelijkeBinnenruimteWoningen')}
                              control={control}
                              errors={errors} labelWidth={400} />
          <DecimailInputField label="Gemeenschappelijke binnenruimten bouwlaag"
                              register={register}
                              name={getPropertyName<CreateUpdateType>('bvoGemeenschappelijkeBinnenruimteBouwlaag')}
                              control={control}
                              errors={errors} labelWidth={400} />
          <DecimailInputField label="Gemeenschappelijke binnenruimten gebouw"
                              register={register}
                              name={getPropertyName<CreateUpdateType>('bvoGemeenschappelijkeBinnenruimteGebouw')}
                              control={control}
                              errors={errors} labelWidth={400} />
          <DecimailInputField label="Gemeenschappelijke niet overdekte buitenruimten"
                              register={register}
                              name={getPropertyName<CreateUpdateType>('bvoGemeenschappelijkeNietOverdekteBuitenruimten')}
                              control={control}
                              errors={errors} labelWidth={400} />
          <DecimailInputField label="Overige ruimten" name={getPropertyName<CreateUpdateType>('bvoOverigeRuimten')}
                              register={register}
                              control={control}
                              errors={errors} labelWidth={400} />
        </InputCard>

        <InputCard title="Verhuurbare vloeroppervlakte" marginTop={'12px'} boldTitle width="100%">
          <DecimailInputField label="Unit" name={getPropertyName<CreateUpdateType>('vvoUnit')} control={control}
                              register={register}
                              errors={errors} labelWidth={400} />
          <DecimailInputField label="Gemeenschappelijke ruimten"
                              register={register}
                              name={getPropertyName<CreateUpdateType>('vvoGemeenschappelijkeRuimten')} control={control}
                              errors={errors} labelWidth={400} />
          <DecimailInputField label="Overige ruimten" name={getPropertyName<CreateUpdateType>('vvoOverigeRuimten')}
                              register={register}
                              control={control}
                              errors={errors} labelWidth={400} />
        </InputCard>
        <br />
        {isVastgoedbeheer ?
          <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isSubmitting ?? false}
                               onOkClick={() => handleSubmit(onSubmit, (errors) => {
                                 setError('Kan niet opslaan vanwege validatiefouten op de invoer.')
                                 console.log(errors)
                               })} onCancelClick={props.dismissPanel} /> : null}
      </form>
    </Panel>
  )
}