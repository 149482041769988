import { IColumn, Link, SelectionMode } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { createCustomRenderedColumn } from 'lib/gridHelper'
import { fetchAll, getLoadingState, ITenantRuimteSoort, select, selectAll } from './tenantRuimteSoortenSlice'
import {
  fetchAll as fetchAllRuimtesoorten,
  selectAll as selectAllRuimteSoort
} from '../ruimtesoortErp/ruimtesoortErpSlice'
import { fetchAll as fetchAllDefectLocaties } from '../defectLocatie/defectLocatieSlice'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import FluentTable from 'components/FluentTable'
import { TenantRuimteSoortEditPanel } from './editPanel'
import { useBoolean } from '@fluentui/react-hooks'

const TenantRuimteSoortList: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const items = useSelector(selectAll)
  const ruimteSoorten = useSelector(selectAllRuimteSoort)
  const loadingState = useSelector(getLoadingState)
  const refreshItems = () => dispatch(fetchAll())
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])

  useEffect(() => {
    refreshItems()
    dispatch(fetchAllRuimtesoorten())
    dispatch(fetchAllDefectLocaties())
  }, [])

  const handleItemInvoked = (id: string) => {
    dispatch(select(id))
    openPanel()
  }

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' }
  ], [selectedItems.length])

  const renderRuimteSoort = (ruimtesoortId: number) => {
    return <>{ruimteSoorten.find(x => x.ruimtesoortErpId === ruimtesoortId)?.omschrijving}</>
  }

  const columns: IColumn[] = [
    createCustomRenderedColumn('Defectlocatie', getPropertyName<ITenantRuimteSoort>('defectLocatieNaam'), (x: ITenantRuimteSoort) =>
      <Link key={x.defectLocatieId}
            onClick={() => handleItemInvoked(x.defectLocatieId.toString())}>{x.defectLocatieNaam}</Link>, 'XXL', false),
    createCustomRenderedColumn('Ruimtesoort ERP', getPropertyName<ITenantRuimteSoort>('ruimtesoortErpOmschrijving'), (x: ITenantRuimteSoort) => renderRuimteSoort(x.ruimtesoortErpId), 'XXL', false)
  ]

  return (<MainTemplate title='Defectlocaties || Ruimtesoorten' subTitle='Overzicht' commandItems={commandItems}>
    <TenantRuimteSoortEditPanel
      dismissPanel={dismissPanel}
      isOpen={isPanelOpen}
    />

    <div style={{ height: '77vh' }}>
      <FluentTable
        columns={columns}
        items={items}
        loading={loadingState === 'pending'}
        selectionMode={SelectionMode.multiple}
        onSelectionChanged={(ids: string[]) => setSelectedItems(ids)}
        onGetKey={(item: ITenantRuimteSoort) => item.defectLocatieId.toString()}
        onItemInvoked={(id: string) => {
          handleItemInvoked(id)
        }}
      />
    </div>
  </MainTemplate>)
}

export default TenantRuimteSoortList